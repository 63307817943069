import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LeftMenu from '../../components/LeftMenu';
import Breadcrumb from '../../components/Breadcrumb';
import { getPrices, createStripeSession } from '../../actions/stripe.action';
import { Button } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { FormattedMessage } from 'react-intl';

const stripePromise = loadStripe('pk_live_RyYnVujd4B7aYoUAwIZyaAJs00bv7xWBhu');

class Subscription extends React.Component {

  state = {
    prices: [],
    showPrices: true,
    plan: '',
    status: ''
  }

  componentDidMount = () => {
    this.props.getPrices();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.stripe.data !== this.props.stripe.data) {

      if (this.props.stripe.data.prices && this.props.stripe.data.prices.length > 0) {

        let status = 'not finished';
        if (this.props.stripe.data.status) {
          status = this.props.stripe.data.status;
        }

        let credentials = JSON.parse(localStorage.getItem('taskCredentials'));
        credentials['subscription_status'] = status;
        localStorage.setItem('taskCredentials', JSON.stringify(credentials));

        this.setState({
          showPrices: !this.props.stripe.data.created_user,
          prices: this.props.stripe.data.prices,
          status,
          plan: this.props.stripe.data.plan
        });
      }

      if (this.props.stripe.data.url && this.props.stripe.data.session) {
        if (this.state.showPrices) {
          this.redirectToStripe();
        } else {
          window.location = this.props.stripe.data.url;
        }
      }
    }
  }

  redirectToStripe = async () => {
    const stripe = await stripePromise;
    stripe.redirectToCheckout({ sessionId: this.props.stripe.data.session.id });
  }

  selectPlan = (plan) => {
    this.props.createStripeSession(plan);
  }

  render () {
    return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right price-card">
                  { !this.state.showPrices && 
                    <div className="select-plan">
                      <h2><FormattedMessage id="pay_or_change_your_plan" /></h2>
                      <div><FormattedMessage id="current_plan" />: {this.state.plan}</div>
                      <div><FormattedMessage id="plan_status" />: {this.state.status}</div>
                      <div className="input-form button-form">
                        <Button onClick={() => this.selectPlan('')}>
                          <FormattedMessage id="click_here_and_manage_your_selected_plan" />
                        </Button>
                      </div>
                    </div>
                  }
                  { this.state.showPrices && 
                    <>
                      <h2><FormattedMessage id="select_your_plan" /></h2>
                      <div className="price-card-content">
                        { this.state.prices.map((item) => (
                          <div className="price-card-item" key={`price-${item.id}`}>
                            <h4><FormattedMessage id={item.lookup_key} /></h4>
                            <h5>$ {item.unit_amount/100}</h5>
                            <ul>
                              <li><FormattedMessage id="add_more_than_3_puppies_for_sale" /></li>
                              <li><FormattedMessage id="add_more_than_1_photo" /></li>
                              <li><FormattedMessage id="add_video" /></li>
                              <li><FormattedMessage id="dog_breed_directory_page" /></li>
                              <li><FormattedMessage id="visitors_see_contact_info" /></li>
                            </ul>
                            <div className="input-form button-form">
                              <Button onClick={() => this.selectPlan(item.lookup_key)}>
                                <FormattedMessage id="submit" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  }
                </div>
              </div>                
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  stripe: {
    data: state.stripeReducer.data,
    loading: state.stripeReducer.loading,
    error: state.stripeReducer.error
  }
});

const mapDispatchToProps = {
  getPrices,
  createStripeSession
};


export default connect(structuredSelector, mapDispatchToProps)(Subscription);
