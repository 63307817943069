import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import LeftMenu from '../../../components/LeftMenu';
import Breadcrumb from '../../../components/Breadcrumb';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import { getPuppyWanteds, destroyPuppyWanted } from '../../../actions/puppy_wanted.action';
import Pagination from '@material-ui/lab/Pagination';
import imagePlaceholder from 'images/dog_placeholder.png';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

class Listing extends React.Component {
  state = {
    breeds: [],
    countries: [],
    products: [],
    activePage: 1,
    total: 0,
    pages: 0,
    status: ''
  }

  componentDidMount = () => {
    this.props.getPuppyWanteds(1);
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    this.setState({status: credentials.subscription_status});
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.product.data !== this.props.product.data) {
      this.setState({
        total: this.props.product.data.total,
        pages: this.props.product.data.total_pages,
        products: this.props.product.data.puppy_wanteds
      });
    }
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({activePage: pageNumber});
    this.props.getPuppyWanteds(pageNumber);
  };

  deleteProduct = (id) => {
    this.props.destroyPuppyWanted(id);
  };

  render () {
  	return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right">
                  <h2><FormattedMessage id="puppy_wanted_create_title" /></h2>
                  { this.state.status !== 'active' &&
                    <div className="new-puppy-link">
                      <Link to={`/subscriptions`}><FormattedMessage id="subscribe_to_create_more_3" /></Link>
                    </div>
                  }
                  <div className="products-list">
                    <div className="input-form button-form">
                      <Button color="primary" onClick={() => this.props.history.push('/new-puppy-wanted-create')}><FormattedMessage id="puppy_wanted_create_new" /></Button>
                      { this.props.product.loading &&
                        <CircularProgress size={26} />
                      }
                    </div>
                    { ( !this.props.product.loading && this.state.products.length === 0 ) &&
                      <p><FormattedMessage id="no_puppy_wanted_added_yet" /></p>
                    }
                    <ul>
                      { this.state.products.map(item => (
                        <li key={`puppie-${item.id}`}>
                          <div className="product-content">{item.id} / {item.breed.name} / {item.gender}</div>
                          <div className="input-form button-form">
                            <Button color="primary" onClick={() => this.props.history.push(`/edit-puppy-wanted/${item.id}`)}><FormattedMessage id="edit" /></Button>
                          </div>
                          <div className="input-form button-form">
                            <Button color="primary" onClick={() => this.deleteProduct(item.id)}><FormattedMessage id="remove" /></Button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    { this.state.pages > 0 &&
                      <div className="pagination">
                        <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  product: {
    data: state.puppyWantedReducer.data,
    loading: state.puppyWantedReducer.loading,
    error: state.puppyWantedReducer.error
  }
});

const mapDispatchToProps = {
  getPuppyWanteds,
  destroyPuppyWanted
};

export default connect(structuredSelector, mapDispatchToProps)(Listing);
