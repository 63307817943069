import {
  BREEDER_LOADING,
  BREEDER_SUCCESS,
  BREEDER_ERROR,
  GET_BREEDER_LOADING,
  GET_BREEDER_SUCCESS,
  GET_BREEDER_ERROR
} from "../actions/breeder.action";

const initialState = {
  data: [],
  loading: false,
  error: ''
};

export default function breederReducer(state = initialState, action) {
  switch (action.type) { 
    case BREEDER_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case BREEDER_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case BREEDER_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    }
    case GET_BREEDER_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case GET_BREEDER_SUCCESS: {
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case GET_BREEDER_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
 }
