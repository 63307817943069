import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import LeftMenu from '../../../components/LeftMenu';
import MuiAutoComplete from '../../../components/MuiAutoComplete';
import Breadcrumb from '../../../components/Breadcrumb';
import {
  FormLabel,
  TextField,
  CircularProgress,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextareaAutosize,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Slider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBreed, cancelBreed } from '../../../actions/breed.action';
import { getCountries } from '../../../actions/country.action';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';
import { getPuppyWanted, updatePuppyWanted } from '../../../actions/puppy_wanted.action';
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const numbChildren = props.puppyData.children ? Array(props.puppyData.children.length).fill("") : null
  const numbDogs = props.puppyData.dogs ? Array(props.puppyData.dogs.length).fill("") : null
  const [gender] = React.useState();
  const [childrenFields, setChildrenFields] = React.useState(numbChildren);
  const [dogsFields, setDogsFields] = React.useState(numbDogs);
  
  const timePeriods = [
    {name: 'Within 1 Week', value: '1w'},
    {name: '2 Weeks', value: '2w'},
    {name: '3 Weeks', value: '3w'},
    {name: '1 Month', value: '1m'},
    {name: '1-3 Months', value: '3m'},
    {name: '3-6 Months', value: '6m'},
    {name: '6+ Months', value: '6+m'},
  ]

  const hoursAloneOptions = [
    {name: '< 1 hour'},
    {name: '1-2 hours'},
    {name: '2-4 hours'},
    {name: '4-6 hours'},
    {name: '8-10 hours'},
    {name: '10+ hours'},
  ]

  const howMuchOptions = [
    {name: 'up to $500'},
    {name: 'up to $1000'},
    {name: 'up to $1500'},
    {name: 'up to $2000'},
    {name: 'up to $2500'},
    {name: 'up to $3000'},
    {name: 'over $3000'},
  ]

  const deliverOptions = [
    {name: 'Pick-up'},
    {name: 'Shipped'},
    {name: 'No Preference'},
  ]

  const propertyTypes = [
    {name: 'Apartment', value: 'apartment'},
    {name: 'Condo', value: 'condo'},
    {name: 'Townhouse', value: 'townhouse'},
    {name: 'Single Family Household', value: 'single_family_household'},
    {name: 'Farm', value: 'farm'},
  ]

  const langOptions = [
    { code: 'en', name: intl.formatMessage({ id: 'english' })},
    { code: 'es', name: intl.formatMessage({ id: 'spanish' })},
    { code: 'pt', name: intl.formatMessage({ id: 'portuguese' })}
  ];

  const optionsRange = (size) => {
    return Array.apply(null, {length: size}).map((_, index) => {
      const value = index + 1;
      const name = String(value).padStart(2, '0');
      return { name: name, value: value }
    });
  }

  const language = langOptions.filter((item) => item.code === props.puppyData.preferred_language)[0];
  const time_period = timePeriods.filter((item) => item.name === props.puppyData.time_period)[0];
  const property_type = propertyTypes.filter((item) => item.name === props.puppyData.property_type)[0];
  const preferred_delivery = deliverOptions.filter((item) => item.name === props.puppyData.preferred_delivery)[0];
  const how_much = howMuchOptions.filter((item) => item.name === props.puppyData.how_much)[0];
  const hours_alone = hoursAloneOptions.filter((item) => item.name === props.puppyData.hours_alone)[0];

  let defaultValues = {
    first_name: props.puppyData.first_name,
    last_name: props.puppyData.last_name,
    breed: props.puppyData.breed,
    gender: props.puppyData.gender,
    time_period: time_period,
    country: props.puppyData.country,
    city: props.puppyData.city,
    state: props.puppyData.state,
    phone: props.puppyData.phone,
    property_type: property_type,
    have_a_yard: props.puppyData.have_a_yard,
    language: language,
    have_children_in_household: props.puppyData.children.length > 0,
    number_of_children: props.puppyData.children.length,
    children: props.puppyData.children,
    have_dogs_in_household: props.puppyData.dogs.length > 0,
    number_of_dogs_in_household: props.puppyData.dogs.length,
    dogs: props.puppyData.dogs,
    have_allergies: props.puppyData.have_allergies,
    hours_alone: hours_alone,
    how_much: how_much,
    preferred_delivery: preferred_delivery,
    why: props.puppyData.why,
    scale: props.puppyData.scale || 0,
    submit_your_profile_for_additional_breeds: props.puppyData.submit_your_profile_for_additional_breeds
  }

  if (props.puppyData.children && props.puppyData.children.length > 0) {
    const childrenOpt = optionsRange(18)
    defaultValues.children = props.puppyData.children.map((it) => (
      {"age": childrenOpt.filter((opt) => it.age === opt.value)[0]}
    ))

    const childrenOpt2 = optionsRange(6)
    defaultValues.number_of_children =  childrenOpt2.filter((opt) => props.puppyData.children.length === opt.value)[0]
  }

  if (props.puppyData.dogs && props.puppyData.dogs.length > 0) {
    defaultValues.dogs = props.puppyData.dogs.map((it) => (
      {"breed_id": props.breeds.filter((opt) => it.breed_id === opt.id)[0]}
    ))

    const dogsOpt = optionsRange(6)
    defaultValues.number_of_dogs =  dogsOpt.filter((opt) => props.puppyData.dogs.length === opt.value)[0]
  }

  const { register, handleSubmit, watch, errors, control, setValue, getValues, reset } = useForm({
    defaultValues: defaultValues
  });


  const onSubmit = data => {
    const params = {puppy_wanted: data}

    params.id = props.puppyData.id;

    if (data.children) {
      const children = data.children.map((it) => (
        {"age":it.age.value}
      ))

      console.log(children, 'children')
      params.puppy_wanted.puppy_wanted_children_attributes = children;
    }

    if (data.dogs) {
      const dogs = data.dogs.map((it) => (
        {"breed_id":it.breed_id.value}
      ))

      console.log(dogs, 'dogs')
      params.puppy_wanted.puppy_wanted_dogs_attributes = dogs;
    }
    
    params.puppy_wanted.user_attributes = {
      city: data.city,
      state: data.state,
      country_id: data.country.id,
      phone: data.phone,
      first_name: data.first_name,
      last_name: data.last_name
    }

    params.puppy_wanted.preferred_language = data.language.code;
    params.puppy_wanted.property_type = data.property_type.name;
    params.puppy_wanted.time_period = data.time_period.name;
    params.puppy_wanted.breed_id = data.breed.value;
    params.puppy_wanted.hours_alone = data.hours_alone.name;
    params.puppy_wanted.how_much = data.how_much.name;
    params.puppy_wanted.preferred_delivery = data.preferred_delivery.name;
    
    props.submit(params);
  };

  const {have_children_in_household, number_of_children, have_dogs_in_household, number_of_dogs } = watch()

  const handleGenderChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: "12px" }}>
      <div className="input-form">
        <div>
          <TextField inputRef={register({ required: true })} name="first_name" size="small" label={intl.formatMessage({ id: 'first_name' })} variant="outlined" />
          {errors.first_name && errors.first_name.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register({ required: true })} name="last_name" size="small" label={intl.formatMessage({ id: 'last_name' })} variant="outlined" />
          {errors.last_name && errors.last_name.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      <div className="input-form">  
        <div>
          <MuiAutoComplete loading={props.breederLoading} label={intl.formatMessage({ id: 'breeds' })} options={props.breeds} name="breed" control={control} />
          {errors.breed && errors.breed.type === "required" && <span className="error"><FormattedMessage id="breed_is_required" /></span>}
        </div>
      </div>      
      <div className="input-form input-radio">
        <div>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              as={
                <RadioGroup
                  aria-label="gender"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'male' })}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'female' })}
                  />
                </RadioGroup>
              }
            />
          </FormControl>
          {errors.gender && errors.gender.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>

      <div className="input-form">  
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'puppy_wanted_create_time_period' })} options={timePeriods} name="time_period" control={control} />
          {errors.time_period && errors.time_period.type === "required" && <span className="error"><FormattedMessage id="puppy_wanted_create_time_period" /></span>}
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <MuiAutoComplete loading={props.countryLoading} label={intl.formatMessage({ id: 'country_of_location' })} options={props.countries} name="country" control={control} />
          {errors.country && errors.country.type === "required" && <span className="error"><FormattedMessage id="country_is_required" /></span>}
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <TextField
            inputRef={register({ required: true })}
            name="city"
            size="small"
            label={intl.formatMessage({ id: 'city' })}
            variant="outlined"
          />
          {errors.city && errors.city.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <TextField
            inputRef={register({ required: true })}
            name="state"
            size="small"
            label={intl.formatMessage({ id: 'state' })}
            variant="outlined"
          />
           {errors.state && errors.state.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <TextField
            inputRef={register({ required: true })}
            name="phone"
            size="small"
            label={intl.formatMessage({ id: 'phone' })}
            variant="outlined"
          />
          {errors.phone && errors.phone.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      
      <div className="input-form">  
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'puppy_wanted_property_type' })} options={propertyTypes} name="property_type" control={control} />
          {errors.property_type && errors.property_type.type === "required" && <span className="error"><FormattedMessage id="puppy_wanted_property_type" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <div className="form-checkbox">
            <Controller
              control={control}
              name="have_a_yard"
              as={<Checkbox />}
            />
            <span>
              <FormattedMessage id="puppy_wanted_have_a_yard" />
            </span>
          </div>
        </div>
      </div>

      <div className="input-form">  
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'prefered_language' })} options={langOptions} name="language" control={control} />
          {errors.language && errors.language.type === "required" && <span className="error"><FormattedMessage id="language_is_required" /></span>}
        </div>
      </div>

      <div className="input-form">
        <div>
          <div className="form-checkbox">
            <Controller
              control={control}
              name="have_children_in_household"
              as={<Checkbox />}
            />
            <span>
              <FormattedMessage id="puppy_wanted_have_children_in_household" />
            </span>
          </div>
        </div>
      
        { have_children_in_household &&
          <div className="input-form">
            <div style={{ marginLeft: "40px" }}>
              <div>
                <MuiAutoComplete
                  required={false}
                  label={intl.formatMessage({ id: 'puppy_wanted_number_of_children' })}
                  options={optionsRange(6)}
                  name="number_of_children"
                  control={control}
                  callback={(data) => {
                    setChildrenFields(Array(data.value).fill(""))
                  }} />
              </div>
            </div>
          </div>
        }
        { have_children_in_household && childrenFields && childrenFields.length > 0 &&
            <div style={{ marginLeft: "60px" }}>
              <div>
                {childrenFields.map((_, index) => (
                  <div key={index}>
                    <div className="input-form">
                      <div>
                        <div className="form-checkbox">
                          <MuiAutoComplete
                            required={false}
                            label={intl.formatMessage(
                              { id: 'puppy_wanted_age_of_child' },
                              { number: index + 1 })}
                            options={optionsRange(18)}
                            name={`children[${index}].age`}
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        }
      </div>
      <div className="input-form">
        <div>
          <div className="form-checkbox">
            <Controller
              control={control}
              name="have_dogs_in_household"
              as={<Checkbox />}
            />
            <span>
              <FormattedMessage id="puppy_wanted_have_dogs_in_household" />
            </span>
          </div>
        </div>

        { have_dogs_in_household &&
          <div className="input-form">
            <div style={{ marginLeft: "40px" }}>
              <div>
                <MuiAutoComplete
                  required={false}
                  label={intl.formatMessage({ id: 'puppy_wanted_number_of_dogs' })}
                  options={optionsRange(6)}
                  name="number_of_dogs"
                  control={control}
                  callback={(data) => {
                    console.log(Array(data.value).fill(''))
                    setDogsFields(Array(data.value).fill(''))
                  }} />
              </div>
            </div>
          </div>
        }
        { have_dogs_in_household && dogsFields && dogsFields.length > 0 &&
          <div style={{ marginLeft: "60px" }}>
            <div>
              {dogsFields.map((_, index) => (
                <div key={index}>
                  <div className="input-form">
                    <div>
                      <div className="form-checkbox">
                        <MuiAutoComplete
                          required={false}
                          label={intl.formatMessage(
                            { id: 'puppy_wanted_dog_breed' },
                            { number: index + 1 })}
                          options={props.breeds}
                          name={`dogs[${index}].breed_id`}
                          control={control}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
      <div className="input-form">
        <div>
          <div className="form-checkbox">
            <Controller
              control={control}
              name="have_allergies"
              as={<Checkbox />}
            />
            <span>
              <FormattedMessage id="puppy_wanted_have_allergies" />
            </span>
          </div>
        </div>
      </div>
      <div className="input-form">
        <div>
          <MuiAutoComplete
            label={intl.formatMessage({ id: 'puppy_wanted_hours_alone' })}
            options={hoursAloneOptions}
            name="hours_alone"
            control={control}
          />
          {errors.hours_alone && errors.hours_alone.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <MuiAutoComplete
            required={false}
            label={intl.formatMessage({ id: 'puppy_wanted_how_much' })}
            options={howMuchOptions}
            name="how_much"
            control={control}
          />
          {errors.how_much && errors.how_much.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <MuiAutoComplete
            required={false}
            label={intl.formatMessage({ id: 'puppy_wanted_delivery' })}
            options={deliverOptions}
            name="preferred_delivery"
            control={control}
          />
          {errors.preferred_delivery && errors.preferred_delivery.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField
            inputRef={register}
            name="why"
            size="small"
            label={intl.formatMessage({ id: 'puppy_wanted_why' })}
            variant="outlined"
            multiline={true}
            rows={5}
          />
          {errors.why && errors.why.type === "required" && <span className="error"><FormattedMessage id="field_required" /></span>}
        </div>
      </div>
      {/* <div className="input-form">
        <div>
          <Typography id="puppy-wanted-scale" gutterBottom>
            <FormattedMessage id="puppy_wanted_scale" />
          </Typography>
          <Slider
            defaultValue={0}
            aria-labelledby="puppy-wanted-scale"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={0}
            max={10}
            style={{color: '#911821'}}
          />
        </div>
      </div> */}

      {/* <div className="input-form">
        <div>
          <div className="form-checkbox">
            <Controller
              control={control}
              name="submit_your_profile_for_additional_breeds"
              as={<Checkbox />}
            />
            <span>
              <FormattedMessage id="puppy_wanted_submit_your_profile" />
            </span>
          </div>
        </div>
      </div> */}
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="add" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class EditPuppyWanted extends React.Component {

  state = {
    breeds: [],
    countries: [],
    status: '',
    puppyWanted: null,
    puppyId: null,
    submitted: false
  }

  componentDidMount = () => {
    this.props.getBreed();
    this.props.getCountries();

    if (this.props.match.params.id) {
      this.startComponent();
    }

    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    this.setState({status: credentials.subscription_status});
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.puppyWanted.data !== this.props.puppyWanted.data && this.state.submitted) {
      if (this.props.puppyWanted.data) {
        this.props.history.push('/puppy-wanted-create');
      }
    }
    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds,
        total: this.props.breed.data.total,
        pages: this.props.breed.data.total_pages
      });
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.startComponent();
    }

    if (prevProps.puppyWanted.data !== this.props.puppyWanted.data) {
      this.setState({
        puppyWanted: this.props.puppyWanted.data
      });
    }
  }

  componentWillUnmount = () => {
    this.props.cancelBreed();
  }

  startComponent = () => {
    const puppyId = this.props.match.params.id;
    this.props.getPuppyWanted(puppyId)
    this.setState({puppyId});
  }

  submit = (data) => {
    this.setState({submitted: true})
    this.props.updatePuppyWanted(data);
  }

  render () {
  	return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right">
                  <div className="new-puppy">
                    <h2><FormattedMessage id="puppy_wanted_create_title" /></h2>
                    { !this.state.puppyWanted && this.state.breeds && this.state.breeds.length === 0 &&
                      <div className="loader whole-content">
                        <CircularProgress />
                      </div>
                    }
                    { this.state.puppyWanted && this.state.breeds && this.state.breeds.length > 0 &&
                      <Form
                        breederLoading={this.props.breed.loading}
                        countryLoading={this.props.country.loading}
                        loading={this.props.product.loading}
                        puppyData={this.state.puppyWanted}
                        error={this.props.product.error}
                        submit={(data) => this.submit(data)}
                        countries={this.state.countries}
                        breeds={this.state.breeds}
                        active={this.state.status}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  product: {
    data: state.productReducer.data,
    loading: state.productReducer.loading,
    error: state.productReducer.error
  },
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  },
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  },
  puppyWanted: {
    data: state.puppyWantedReducer.data,
    loading: state.puppyWantedReducer.loading,
    error: state.puppyWantedReducer.error
  }
});

const mapDispatchToProps = {
  updatePuppyWanted,
  getBreed,
  getPuppyWanted,
  getCountries,
  cancelBreed
};

export default connect(structuredSelector, mapDispatchToProps)(EditPuppyWanted);
