import {
  PUPPY_WANTED_LOADING,
  PUPPY_WANTED_SUCCESS,
  PUPPY_WANTED_ERROR,
  PUPPY_WANTED_CREATE_LOADING,
  PUPPY_WANTED_CREATE_SUCCESS,
  PUPPY_WANTED_CREATE_ERROR,
  GET_PUPPY_WANTED_LOADING,
  GET_PUPPY_WANTED_SUCCESS,
  GET_PUPPY_WANTED_ERROR,
  PUPPY_WANTED_UPDATE_LOADING,
  PUPPY_WANTED_UPDATE_SUCCESS,
  PUPPY_WANTED_UPDATE_ERROR,
  PUPPY_WANTED_DESTROY_LOADING,
  PUPPY_WANTED_DESTROY_ERROR,
  PUPPY_WANTED_DESTROY_SUCCESS,
} from "../actions/puppy_wanted.action";

const initialState = {
  data: [],
  loading: false,
  error: ''
};

export default function puppyWantedReducer(state = initialState, action) {
  switch (action.type) {
    case PUPPY_WANTED_LOADING: {
      return {
        ...state,
        loading: true,
        error:''
      };
    }
    case PUPPY_WANTED_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false
      }
    }
    case PUPPY_WANTED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case PUPPY_WANTED_CREATE_LOADING: { 
      return { 
          ...state, 
          loading: true, 
          error:'' 
      }; 
  } 
  case PUPPY_WANTED_CREATE_SUCCESS: { 
      return { 
          ...state, 
          data: action.data, 
          loading: false 
      } 
  } 
  case PUPPY_WANTED_CREATE_ERROR: { 
      return { 
          ...state, 
          loading: false, 
          error: action.error 
      }; 
  }
  case PUPPY_WANTED_UPDATE_LOADING: { 
    return { 
        ...state, 
        loading: true, 
        error:'' 
    }; 
  } 
  case PUPPY_WANTED_UPDATE_SUCCESS: { 
      return { 
          ...state, 
          data: action.data, 
          loading: false 
      } 
  } 
  case PUPPY_WANTED_UPDATE_ERROR: { 
      return { 
          ...state, 
          loading: false, 
          error: action.error 
      }; 
  } 
    case GET_PUPPY_WANTED_LOADING: {
      return {
        ...state,
        loading: true,
        error:''
      };
    }
    case GET_PUPPY_WANTED_SUCCESS: {
      return {
        ...state,
        data: action.data,
        loading: false
      }
    }
    case GET_PUPPY_WANTED_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
    case PUPPY_WANTED_DESTROY_LOADING: {
        return {
            ...state,
            loading: true,
            error:''
        };
    }
    case PUPPY_WANTED_DESTROY_SUCCESS: {
        return {
            ...state,
            data: action.data,
            loading: false
        }
    }
    case PUPPY_WANTED_DESTROY_ERROR: {
        return {
            ...state,
            loading: false,
            error: action.error
        };
    }
    default: {
      return state;
    }
  }
 }
