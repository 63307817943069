import React from "react";
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

export default function BreedItem({
  id,
  image,
  name,
  category,
  country,
  color,
  weight,
  height,
  playfulness,
  trainability, 
  watch_dog_ability,
  exercise_needs,
  dog_friendly,
  onHandleForSale
}) {
  return (
    <div className="find-my-breed-item">
      <img src={image} />
      <div className="breed-list-content">
        <h3>{name}</h3>
        <p>
          <FormattedMessage id="group" />: {category}<br />
          <FormattedMessage id="country" />: {country}<br />
          <FormattedMessage id="color" />: {color}<br />
          <FormattedMessage id="weight" />: {weight}<br />
          <FormattedMessage id="height" />: {height}<br />
        </p>
      </div>
      <div className="breed-box">
        <div className="input-form button-form">
          <Button className="btn" onClick={() => onHandleForSale(id)}>
            <FormattedMessage id="for_sale" />
          </Button>
        </div>
        <div className="breed-box-item">
          <span className="breed-box-title"><FormattedMessage id="playfulness" /></span>
          <Rating icon={<FavoriteIcon fontSize="inherit" />} name="size-small" size="small" value={playfulness} readOnly />
        </div>
        <div className="breed-box-item">
          <span className="breed-box-title"><FormattedMessage id="trainability" /></span>
          <Rating icon={<FavoriteIcon fontSize="inherit" />} name="size-small" size="small" value={trainability} readOnly />
        </div>
        <div className="breed-box-item">
          <span className="breed-box-title"><FormattedMessage id="watch_dog" /></span>
          <Rating icon={<FavoriteIcon fontSize="inherit" />} name="size-small" size="small" value={watch_dog_ability} readOnly />
        </div>
        <div className="breed-box-item">
          <span className="breed-box-title"><FormattedMessage id="exercise_needs" /></span>
          <Rating icon={<FavoriteIcon fontSize="inherit" />} name="size-small" size="small" value={exercise_needs} readOnly />
        </div>
        <div className="breed-box-item">
          <span className="breed-box-title"><FormattedMessage id="dog_friendly" /></span>
          <Rating icon={<FavoriteIcon fontSize="inherit" />} name="size-small" size="small" value={dog_friendly} readOnly />
        </div>
      </div>
    </div>
  );
}

