import Api from "../services/Api"

export const KID_ROUTINE_LOADING = 'KID_ROUTINE_LOADING';
export const KID_ROUTINE_SUCCESS = 'KID_ROUTINE_SUCCESS';
export const KID_ROUTINE_ERROR = 'KID_ROUTINE_ERROR';
export const KID_ROUTINE_UPDATE_LOADING = 'KID_ROUTINE_UPDATE_LOADING';
export const KID_ROUTINE_UPDATE_SUCCESS = 'KID_ROUTINE_UPDATE_SUCCESS';
export const KID_ROUTINE_UPDATE_ERROR = 'KID_ROUTINE_UPDATE_ERROR';

export const loadKidRoutine = (routineId) => dispatch => {

   dispatch({ type: KID_ROUTINE_LOADING });

    Api.getKidRoutine(routineId)
       .then(response => response.json())
       .then(
            data => dispatch({ type: KID_ROUTINE_SUCCESS, data }),
            error => dispatch({ type: KID_ROUTINE_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};

export const updateKidRoutine = (id, routineId, startTime, endTime) => dispatch => {

    dispatch({ type: KID_ROUTINE_LOADING });
 
     Api.updateKidRoutine(id, routineId, startTime, endTime)
        .then(response => response.json())
        .then(
             data => dispatch({ type: KID_ROUTINE_SUCCESS, data }),
             error => dispatch({ type: KID_ROUTINE_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
 };