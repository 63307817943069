import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import LeftMenu from '../../../components/LeftMenu';
import MuiAutoComplete from '../../../components/MuiAutoComplete';
import Breadcrumb from '../../../components/Breadcrumb';
import {
  FormLabel,
  TextField,
  CircularProgress,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextareaAutosize,
  Checkbox
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createProduct, updateProduct } from '../../../actions/product.action';
import { getBreed, cancelBreed } from '../../../actions/breed.action';
import { getCountries } from '../../../actions/country.action';
import { useForm, Controller } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';
import { getPuppy } from '../../../actions/puppy.action';
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const [gender] = React.useState();
  
  const langOptions = [
    {
      code: 'en',
      name: intl.formatMessage({ id: 'english' })
    },
    {
      code: 'es',
      name: intl.formatMessage({ id: 'spanish' })
    },
    {
      code: 'pt',
      name: intl.formatMessage({ id: 'portuguese' })
    }
  ];

  const shippingAreaOptions = [
    {
      code: 'world_wide',
      name: intl.formatMessage({ id: 'world_wide' })
    },
    {
      code: 'selected_areas',
      name: intl.formatMessage({ id: 'selected_areas' })
    },
    {
      code: 'described_below',
      name: intl.formatMessage({ id: 'described_below' })
    },
    {
      code: 'not_avaliable',
      name: intl.formatMessage({ id: 'not_avaliable' })
    }
  ];

  const shippingArea = shippingAreaOptions.filter((item) => item.code === props.puppyData.shipping_area)[0];
  const language = langOptions.filter((item) => item.code === props.puppyData.language)[0];

  const { register, handleSubmit, watch, errors, control, setValue } = useForm({
    defaultValues: {
      name: props.puppyData.name,
      breed: props.puppyData.breed,
      country: props.puppyData.country,
      gender: props.puppyData.gender,
      description: props.puppyData.description,
      video: props.puppyData.video,
      price: props.puppyData.price,
      born: props.puppyData.born,
      champion_bloodlines: props.puppyData.champion_bloodlines,
      champion_sired: props.puppyData.champion_sired ? true : false,
      show_potential: props.puppyData.show_potential ? true : false,
      shipping_included: props.puppyData.shipping_included,
      registered_registerable: props.puppyData.registered_registerable,
      veterinarian_examination: props.puppyData.veterinarian_examination,
      health_certificate: props.puppyData.health_certificate,
      health_guarantee: props.puppyData.health_guarantee,
      pedigree: props.puppyData.pedigree,
      travel_crate: props.puppyData.travel_crate,
      language: language,
      shipping_area: shippingArea
    }
  });

  const images = props.puppyData.images.map(item => item.original);
    
  const onSubmit = data => {
    data.images = files;

    data.country_id = data.country.id;
    delete data.country;

    data.breed_id = data.breed.id;
    delete data.breed;

    data.id = props.puppyData.id;

    data.language = data.language.code;

    data.shipping_area = data.shipping_area.code;

    props.submit(data);
  };

  let files = [];

  const handleFileChange = (images) => {
    files = [];
    images.map((item) => {
      files.push(item);
    });
  }

  const handleGenderChange = (event) => {
    setValue(event.target.value);
  };

  const limit = props.active === 'active' ? 20 : 1;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <div>
          <TextField inputRef={register({ required: true })} name="name" size="small" label={intl.formatMessage({ id: 'puppy_name' })} variant="outlined" />
          {errors.name && errors.name.type === "required" && <span className="error"><FormattedMessage id="name_is_required" /></span>}
        </div>
      </div>
      <div className="input-form">  
        <div>
          <MuiAutoComplete loading={props.breederLoading} label={intl.formatMessage({ id: 'breeds' })} options={props.breeds} name="breed" control={control} />
          {errors.breed && errors.breed.type === "required" && <span className="error"><FormattedMessage id="breed_is_required" /></span>}
        </div>
      </div>
      <div className="input-form input-radio">
        <div>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              as={
                <RadioGroup
                  aria-label="gender"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'male' })}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'female' })}
                  />
                </RadioGroup>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField InputLabelProps={{
              shrink: true,
            }}
            type="date" inputRef={register({ required: true })} name="born" size="small" label={intl.formatMessage({ id: 'birth' })}  variant="outlined" />
          {errors.born && errors.born.type === "required" && <span className="error"><FormattedMessage id="birth_is_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div className="input-form-label">
          <FormattedMessage id="check_all_that_apply" />:
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="champion_bloodlines"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="champion_bloodlines" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="champion_sired"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="champion_sired" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="show_potential"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="show_potential" />
          </span>
        </div>
      </div>
      <div className="input-form">
        <div className="form-checkbox country-shipping">
          <Controller
            control={control}
            name="shipping_included"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="shipping_included" />
          </span>
        </div>
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'shipping_area' })} options={shippingAreaOptions} name="shipping_area" control={control} />
          {errors.shipping_area && errors.shipping_area.type === "required" && <span className="error"><FormattedMessage id="shipping_area_is_required" /></span>}
        </div>
      </div>
      { props.active === 'active' &&
        <div className="input-form">
          <div>
            <TextField inputRef={register()} name="video" size="small" label={intl.formatMessage({ id: 'video_url_youtube' })} variant="outlined" />
          </div>
        </div>
      }
      <div className="input-form">
        <div>
          <TextField inputRef={register({ required: true })} name="price" size="small" label={intl.formatMessage({ id: 'price' })} variant="outlined" />
          {errors.price && errors.price.type === "required" && <span className="error"><FormattedMessage id="price_is_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div>
          <MuiAutoComplete loading={props.countryLoading} label={intl.formatMessage({ id: 'country_of_location' })} options={props.countries} name="country" control={control} />
          {errors.country && errors.country.type === "required" && <span className="error"><FormattedMessage id="country_is_required" /></span>}
        </div>
      </div>
      <div className="input-form">
        <div className="input-form-label">
          <FormattedMessage id="included_items" />:
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="registered_registerable"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="registered_registerable" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="veterinarian_examination"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="veterinarian_examination" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="health_certificate"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="health_certificate" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="health_guarantee"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="health_guarantee" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="pedigree"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="pedigree" />
          </span>
        </div>
        <div className="form-checkbox">
          <Controller
            control={control}
            name="travel_crate"
            as={<Checkbox />}
          />
          <span>
            <FormattedMessage id="travel_crate" />
          </span>
        </div>
      </div>

      <div className="input-form">
        <div className="textarea-holder">
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="description"
              as={
                <TextareaAutosize aria-label="minimum height" name="description" rowsMin={5} placeholder={intl.formatMessage({ id: 'description' })} />
              }
            />
          </FormControl>
          {errors.description && errors.description.type === "required" && <span className="error"><FormattedMessage id="description_is_required" /></span>}
        </div>
        <div className="info"><FormattedMessage id="is_not_allowed_contact" /></div>
      </div>

      <div className="input-form">  
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'prefered_language' })} options={langOptions} name="language" control={control} />
          {errors.language && errors.language.type === "required" && <span className="error"><FormattedMessage id="language_is_required" /></span>}
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <FormControl component="fieldset">
            <FormLabel><FormattedMessage id="select_an_image" /></FormLabel>
            <br />
            <DropzoneArea
              onChange={handleFileChange}
              name="images"
              filesLimit={limit}
              initialFiles={images}
            />
          </FormControl>
          <div className="info">
            <br />
            <FormattedMessage id="want_to_add_more" />
          </div>
        </div>
      </div>
      
      { props.error !== '' && 
        <div className="server-error">
          <FormattedMessage id="there_contact_busca_cachorro" />
        </div>
      }
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="add" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class EditPuppy extends React.Component {

  state = {
    breeds: [],
    countries: [],
    puppyId: null,
    puppy: null,
    status: ''
  }

  componentDidMount = () => {
    this.props.getBreed();
    this.props.getCountries();
    if (this.props.match.params.id) {
      this.startComponent();
    }
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    this.setState({status: credentials.subscription_status});
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.product.data !== this.props.product.data) {
      if (this.props.product.data) {
        this.props.history.push('/listing');
      }
    }
    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds,
        total: this.props.breed.data.total,
        pages: this.props.breed.data.total_pages
      });
    }

    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.startComponent();
    }

    if (prevProps.puppy.data !== this.props.puppy.data) {
      this.setState({
        puppy: this.props.puppy.data
      });
    }
  }

  componentWillUnmount = () => {
    this.props.cancelBreed();
  }

  startComponent = () => {
    const puppyId = this.props.match.params.id;
    this.props.getPuppy(puppyId);
    this.setState({puppyId});
  }

  submit = (data) => {
    this.props.updateProduct(data);
  }
  render () {
  	return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right">
                  <div className="new-puppy">
                    <h2><FormattedMessage id="sell_a_puppy" /></h2>
                    { this.state.status !== 'active' &&                    
                      <div className="new-puppy-link">
                        <Link to={`/subscriptions`}><FormattedMessage id="subscribe_to_add_more_3" /></Link>
                      </div>
                    }
                    { !this.state.puppy &&
                      <div className="loader whole-content">
                        <CircularProgress />
                      </div>
                    }
                    { this.state.puppy &&
                      <Form
                        breederLoading={this.props.breed.loading}
                        countryLoading={this.props.country.loading}
                        puppyData={this.state.puppy}
                        loading={this.props.product.loading}
                        error={this.props.product.error}
                        submit={(data) => this.submit(data)}
                        countries={this.state.countries}
                        breeds={this.state.breeds}
                        active={this.state.status}
                      />
                    }
                  </div>
                </div>
              </div>                
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  product: {
    data: state.productReducer.data,
    loading: state.productReducer.loading,
    error: state.productReducer.error
  },
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  },
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  },
  puppy: {
    data: state.puppyReducer.data,
    loading: state.puppyReducer.loading,
    error: state.puppyReducer.error
  }
});

const mapDispatchToProps = {
  createProduct,
  getBreed,
  getCountries,
  getPuppy,
  updateProduct,
  cancelBreed
};

export default connect(structuredSelector, mapDispatchToProps)(EditPuppy);
