import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FormattedMessage } from 'react-intl';
import { Button, Menu, MenuItem } from '@material-ui/core';

const permitedLangs = ['en', 'es', 'pt'];

class Navbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuShown: false,
      user: null,
      anchorEl: null,
      lang: null
    };
  }

  componentDidMount = () => {
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    if (credentials) {
      this.setState({
        user: credentials
      });
    }

    const lang = localStorage.getItem('lang') || "es" ;
    this.setState({
      lang: lang
    })
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.user.data !== this.props.user.data && this.props.user.data.email) {
      this.setState({
        user: this.props.user.data
      });
    }
  }

  showMenu = () => {
    let menuShown = !this.state.menuShown;
    this.setState({menuShown: menuShown});
  }

  logout = () => {
    localStorage.setItem('taskCredentials', null);
    this.setState({
      user: null
    });
    try {
      this.props.history.push(`/${this.state.lang}/login`);
    } catch (e) {
      console.log(e);
    }
  }

  handleClick = (event) => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = (lang) => {
    this.setState({anchorEl: null});
    if (lang && permitedLangs.includes(lang)) {
      var currentLang = window.location.pathname.split('/')[1]
      if (permitedLangs.includes(currentLang)) {
        window.location.href = window.location.pathname.replace(currentLang, lang)
      } else {
        window.location.href = `/${lang}${window.location.pathname}`
      }
    }
  };

  render () {
    const lang = this.state.lang;

  	return (
      <nav>
        <div className={`back-nav ${this.state.menuShown ? "show" : ""}`}>
          <div className="content">
            <div className="logo">
              <Link to="/">
                <FormattedMessage id="busca_cachorro" />
              </Link>
              <button onClick={() => this.showMenu()}><FontAwesomeIcon icon={faBars} /></button>
            </div>
            <div className="menu-content">
              <div className="menu-links">
                <ul>
                  <li>
                    <Link to={`/${lang}/puppies`}>
                      <FormattedMessage id="all_puppies" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/find-my-breed`}>
                      <FormattedMessage id="find_my_bred" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/puppy-wanted`}>
                      <FormattedMessage id="puppy_wanted" />
                    </Link>
                  </li>
                  <li>
                    { this.state.user &&
                      <Link to={`/${lang}/new-puppy`}>
                        <FormattedMessage id="sell_a_puppy" />
                      </Link>
                    }
                    { !this.state.user &&
                      <Link to={`/${lang}/sell-a-puppy`}>
                        <FormattedMessage id="sell_a_puppy" />
                      </Link>
                    }
                  </li>
                  <li>
                    <Link to={`/${lang}/about-us`}>
                      <FormattedMessage id="about_us" />
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${lang}/countries`}>
                      <FormattedMessage id="by_country" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="menu-right">
                <ul>
                  { this.state.user &&
                    <>
                      <li>
                        <button onClick={this.logout}><FormattedMessage id="log_out" /></button>
                      </li>
                      <li>
                        <Link to={`/${lang}/listing`}>
                          <FormattedMessage id="user_area" />
                        </Link>
                      </li>
                    </>
                  }
                  { !this.state.user &&
                    <li>
                      <Link to={`/${lang}/login`}>
                        <FormattedMessage id="login" />
                      </Link>
                    </li>
                  }
                  <li>
                    <Button className="button-language" aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                      <FormattedMessage id="language" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      keepMounted
                      open={Boolean(this.state.anchorEl)}
                    >
                      <MenuItem onClick={() => this.handleClose('en')}><FormattedMessage id="english" /></MenuItem>
                      <MenuItem onClick={() => this.handleClose('es')}><FormattedMessage id="spanish" /></MenuItem>
                      <MenuItem onClick={() => this.handleClose('pt')}><FormattedMessage id="portuguese" /></MenuItem>
                    </Menu>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const structuredSelector = state => ({
  user: {
    data: state.sessionReducer.data
  },
});

const mapDispatchToProps = {};

export default connect(structuredSelector, mapDispatchToProps)(Navbar);
