import Api from "../services/Api"

export const PUPPY_LOADING = 'PUPPY_LOADING';
export const PUPPY_SUCCESS = 'PUPPY_SUCCESS';
export const PUPPY_ERROR = 'PUPPY_ERROR';
export const GET_PUPPY_LOADING = 'GET_PUPPY_LOADING';
export const GET_PUPPY_SUCCESS = 'GET_PUPPY_SUCCESS';
export const GET_PUPPY_ERROR = 'GET_PUPPY_ERROR';

export const getPuppies = (page, q, breed, country) => dispatch => {

   if (!page) page = '';
   if (!q) q = '';
   if (!breed) breed = '';
   if (!country) country = '';

   dispatch({ type: PUPPY_LOADING });
      Api.getPuppies(page, q, breed, country)
         .then(response => response.json())
         .then(
          data => dispatch({ type: PUPPY_SUCCESS, data }),
          error => dispatch({ type: PUPPY_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
};

export const getPuppy = (id) => dispatch => {

   dispatch({ type: GET_PUPPY_LOADING });
      Api.getPuppy(id)
         .then(response => response.json())
         .then(
         data => dispatch({ type: GET_PUPPY_SUCCESS, data }),
         error => dispatch({ type: GET_PUPPY_ERROR, error: error.message || 'Unexpected Error!!!' })
         )
};
