import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from './views/Home';
import AboutUs from './views/AboutUs';
import Clubs from './views/Clubs';
import PuppyWanted from './views/PuppyWanted';
import FindMyBreed from './views/FindMyBreed';
import Puppies from './views/Puppies';
import Login from './views/Login';
import Signup from './views/Signup';
import Forgotten from './views/Forgotten';
import Countries from './views/Countries';
import BreedersCountries from './views/BreedersCountries';
import TermsOfServices from './views/TermsOfServices';
import CurrencyConverter from './views/CurrencyConverter';
import PaymentServices from './views/PaymentServices';
import BuyerProtection from './views/BuyerProtection';
import SellerProtection from './views/SellerProtection';
import Puppy from './views/Puppy';
import Disclaimer from './views/Disclaimer';
import Links from './views/Links';
import User from './views/user/User';
import Listing from './views/user/listing/Listing';
import PuppyWantedCreate from './views/user/puppy_wanted_create/Listing';
import NewPuppyWantedCreate from './views/user/puppy_wanted_create/New';
import EditPuppyWanted from './views/user/puppy_wanted_create/Edit';
import NewPuppy from './views/user/listing/NewPuppy';
import EditPuppy from './views/user/listing/EditPuppy';
import EditProfile from './views/user/EditProfile';
import Subscription from './views/user/Subscription';
import Breeder from './views/Breeder';
import Breeders from './views/Breeders';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import IntlProviderConfigured from './IntlProviderConfigured';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import Kid from './views/tasks/Kid';
import TaskLogin from './views/tasks/TaskLogin';
import ScrollToTop from './ScrollTop';
import ReactGA from 'react-ga';

config.autoAddCss = false;
library.add(faCheckSquare, faCoffee);

const store = configureStore();
const languages = ['en', 'es', 'pt'];

const validate = function() {
  const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
  if(credentials) {
    return true;
  }
  return false;
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        validate() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

class App extends React.Component {
  componentDidMount() {
    ReactGA.initialize('UA-185558988-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render () {
    return (
      <Provider store={store}>
        <IntlProviderConfigured>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                <Route path="/clubs-caninos" component={Clubs} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/puppy-wanted" component={PuppyWanted} />
                <Route path="/:lang/puppy-wanted" component={PuppyWanted} />
                <Route path="/find-my-breed" component={FindMyBreed} />
                <Route path="/puppies/:country?/:breed?" component={Puppies} />
                <Route path="/puppy/:id?/" component={Puppy} />
                <Route path="/login/:subscribe?" component={Login} />
                <Route path="/sign-up/:subscribe?" component={Signup} />
                <Route path="/sell-a-puppy" component={Signup} />
                <Route path="/forgotten" component={Forgotten} />
                <Route path="/countries" component={Countries} />
                <Route path="/breeder/:id" component={Breeder} />
                <Route path="/breeders/:country?" component={Breeders} />
                <Route path="/breeders-countries" component={BreedersCountries} />
                <Route path="/terms-of-service" component={TermsOfServices} />
                <Route path="/disclaimer" component={Disclaimer} />
                <Route path="/links" component={Links} />
                <Route path="/currency-converter" component={CurrencyConverter} />
                <Route path="/payment-services" component={PaymentServices} />
                <Route path="/buyer-protection" component={BuyerProtection} />
                <Route path="/seller-protection" component={SellerProtection} />
                <Route path="/task/login" component={TaskLogin} />
                <Route path="/task/kid/:routineId?" component={Kid} />
                <Route path="/:lang/clubs-caninos" component={Clubs} />
                <Route path="/:lang/about-us" component={AboutUs} />
                <Route path="/:lang/puppy-wanted" component={PuppyWanted} />
                <Route path="/:lang/find-my-breed" component={FindMyBreed} />
                <Route path="/:lang/puppies/:country?/:breed?" component={Puppies} />
                <Route path="/:lang/puppy/:id?/" component={Puppy} />
                <Route path="/:lang/login/:subscribe?" component={Login} />
                <Route path="/:lang/sign-up/:subscribe?" component={Signup} />
                <Route path="/:lang/sell-a-puppy" component={Signup} />
                <Route path="/:lang/forgotten" component={Forgotten} />
                <Route path="/:lang/countries" component={Countries} />
                <Route path="/:lang/breeder/:id" component={Breeder} />
                <Route path="/:lang/breeders/:country?" component={Breeders} />
                <Route path="/:lang/breeders-countries" component={BreedersCountries} />
                <Route path="/:lang/terms-of-service" component={TermsOfServices} />
                <Route path="/:lang/disclaimer" component={Disclaimer} />
                <Route path="/:lang/links" component={Links} />
                <Route path="/:lang/currency-converter" component={CurrencyConverter} />
                <Route path="/:lang/payment-services" component={PaymentServices} />
                <Route path="/:lang/buyer-protection" component={BuyerProtection} />
                <Route path="/:lang/seller-protection" component={SellerProtection} />
                <Route path="/:lang/task/login" component={TaskLogin} />
                <Route path="/:lang/task/kid/:routineId?" component={Kid} />
                <Route exact path="/" component={Home} />
                <Route exact path="/en/" component={Home} />
                <Route exact path="/pt/" component={Home} />
                <Route exact path="/es/" component={Home} />
                <PrivateRoute>
                  <Route path="/user" component={User} />
                  <Route path="/listing" component={Listing} />
                  <Route path="/:lang/puppy-wanted-create" component={PuppyWantedCreate} />
                  <Route path="/puppy-wanted-create" component={PuppyWantedCreate} />
                  <Route path="/:lang/new-puppy-wanted-create" component={NewPuppyWantedCreate} />
                  <Route path="/new-puppy-wanted-create" component={NewPuppyWantedCreate} />
                  <Route path="/edit-puppy-wanted/:id" component={EditPuppyWanted} />
                  <Route path="/:lang/edit-puppy-wanted/:id" component={EditPuppyWanted} />
                  <Route path="/new-puppy" component={NewPuppy} />
                  <Route path="/edit-puppy/:id" component={EditPuppy} />
                  <Route path="/profile" component={EditProfile} />
                  <Route path="/subscriptions" component={Subscription} />
                  <Route path="/:lang/user" component={User} />
                  <Route path="/:lang/listing" component={Listing} />
                  <Route path="/:lang/new-puppy" component={NewPuppy} />
                  <Route path="/:lang/edit-puppy/:id" component={EditPuppy} />
                  <Route path="/:lang/profile" component={EditProfile} />
                  <Route path="/:lang/subscriptions" component={Subscription} />
                </PrivateRoute>
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
        </IntlProviderConfigured>
      </Provider>
    );
  }
}

export default App
