import Api from "../services/Api"

export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const PRODUCT_CREATE_LOADING = 'PRODUCT_CREATE_LOADING';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_ERROR = 'PRODUCT_CREATE_ERROR';
export const PRODUCT_DESTROY_LOADING = 'PRODUCT_DESTROY_LOADING';
export const PRODUCT_DESTROY_SUCCESS = 'PRODUCT_DESTROY_SUCCESS';
export const PRODUCT_DESTROY_ERROR = 'PRODUCT_DESTROY_ERROR';
export const PRODUCT_UPDATE_LOADING = 'PRODUCT_UPDATE_LOADING';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR';

export const getProducts = (page) => dispatch => {

   dispatch({ type: PRODUCT_LOADING });
   if (!page) page = '';

    Api.getProducts(page)
      .then(response => response.json())
      .then(
        data => dispatch({ type: PRODUCT_SUCCESS, data }),
        error => dispatch({ type: PRODUCT_ERROR, error: error.message || 'Unexpected Error!!!' })
      )
};

export const createProduct = (data) => dispatch => {

    dispatch({ type: PRODUCT_CREATE_LOADING });
 
     Api.createProduct(data)
      .then(response => response.json())
      .then(
        data => dispatch({ type: PRODUCT_CREATE_SUCCESS, data }),
        error => dispatch({ type: PRODUCT_CREATE_ERROR, error: error.message || 'Unexpected Error!!!' })
      )
 };

 export const updateProduct = (data) => dispatch => {

  dispatch({ type: PRODUCT_UPDATE_LOADING });

   Api.updateProduct(data)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PRODUCT_UPDATE_SUCCESS, data }),
      error => dispatch({ type: PRODUCT_UPDATE_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};

 export const destroyProduct = (id) => dispatch => {

  dispatch({ type: PRODUCT_DESTROY_LOADING });

   Api.destroyProduct(id)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PRODUCT_DESTROY_SUCCESS, data }),
      error => dispatch({ type: PRODUCT_DESTROY_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};