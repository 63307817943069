import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LeftMenu from '../../components/LeftMenu';
import Breadcrumb from '../../components/Breadcrumb';

class User extends React.Component {
  render () {

  	return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right"></div>
              </div>
                
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = createStructuredSelector({
});

const mapDispatchToProps = {};

export default connect(structuredSelector, mapDispatchToProps)(User);
