import { KID_TASK_UPDATE_LOADING, KID_TASK_UPDATE_SUCCESS, KID_TASK_UPDATE_ERROR } from "../actions/kidTask.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function kidTaskReducer(state = initialState, action) {
    switch (action.type) { 
        case KID_TASK_UPDATE_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case KID_TASK_UPDATE_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case KID_TASK_UPDATE_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        } 
        default: { 
            return state; 
        } 
    } 
 }