import Api from "../services/Api"

export const CATEGORY_LOADING = 'CATEGORY_LOADING';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';

export const getCategories = () => dispatch => {

   dispatch({ type: CATEGORY_LOADING });

    Api.getCategories()
       .then(response => response.json())
       .then(
            data => dispatch({ type: CATEGORY_SUCCESS, data }),
            error => dispatch({ type: CATEGORY_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};