import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import { createSession } from '../actions/session.action';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <TextField inputRef={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} name="email" size="small" label={intl.formatMessage({ id: 'email' })} variant="outlined" />
        {errors.email && errors.email.type === "required" && <span className="error"><FormattedMessage id="email_is_required" /></span>}
        {errors.email && errors.email.type === "pattern" && <span className="error"><FormattedMessage id="email_is_not_valid" /></span>}
      </div>
      <div className="input-form">
        <TextField inputRef={register({ required: true, minLength: 6 })} type="password" name="password" size="small" label={intl.formatMessage({ id: 'password' })} variant="outlined" />
        {errors.password && errors.password.type === "required" && <span className="error"><FormattedMessage id="password_is_required" /></span>}
        {errors.password && errors.password.type === "minLength" && <span className="error"><FormattedMessage id="min_length_is_6" /></span> }
      </div>
      { props.error !== '' && 
        <div className="server-error">
          <FormattedMessage id="email_or_password_invalid" />
        </div>
      }
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="login" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class Login extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      subscribe: false
    }
  }

  componentDidMount = () => {
    console.log(this.props.match)
    if (this.props.match.params.subscribe) {
      this.setState({subscribe: true})
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.session.data !== this.props.session.data) {
      localStorage.setItem('taskCredentials', JSON.stringify(this.props.session.data));

      if (this.state.subscribe) {
        this.props.history.push('/subscriptions');
      } else {
        this.props.history.push('/listing');
      }
    }
  }

  onSubmit = data => {
    console.log(data);

  }
  
  login = (data) => {
    this.props.createSession(data.email, data.password);
  }

  render () {    
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="login" />
              <div className="login-content">
                <div className="form-content">
                  <h2><FormattedMessage id="login" /></h2>
                  <Form
                    loading={this.props.session.loading}
                    error={this.props.session.error}
                    submit={(data) => this.login(data)} 
                  />
                </div>
                <div className="form-links">
                  <ul>
                    <li>
                      <Link to={this.state.subscribe ? '/sign-up/subscribe' : '/sign-up'}>
                        <FormattedMessage id="sign_up" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/forgotten">
                        <FormattedMessage id="forgot_your_password" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  session: {
    data: state.sessionReducer.data,
    loading: state.sessionReducer.loading,
    error: state.sessionReducer.error
  }
});

const mapDispatchToProps = {
  createSession
};

export default connect(structuredSelector, mapDispatchToProps)(Login);
