import Api from "../services/Api"

export const BREED_LOADING = 'BREED_LOADING';
export const BREED_SUCCESS = 'BREED_SUCCESS';
export const BREED_ERROR = 'BREED_ERROR';
export const CANCEL_API_REQUEST = 'CANCEL_API_REQUEST';

export const getBreed = (page, q, category, country) => dispatch => {

   if (!page) page = '';
   if (!q) q = '';
   if (!category) category = '';
   if (!country) country = '';

   dispatch({ type: BREED_LOADING });
      Api.getBreed(page, q, category, country)
         .then(response => response.json())
         .then(
          data => dispatch({ type: BREED_SUCCESS, data }),
          error => dispatch({ type: BREED_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
};

export const cancelBreed = () => dispatch =>  {
   // dispatch({type: CANCEL_API_REQUEST});
   Api.getSignal().abort();
}
