import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import Pagination from '@material-ui/lab/Pagination';
import { getAllBreeders } from '../actions/breeder.action';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';
import ContactBreeder from '../components/ContactBreeder';

class Breeders extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      breeders: [],
      activePage: 1,
      total: 0,
      pages: 0,
      country: null,
    }
  }

  componentDidMount = () => {
    let country = null;
    
    if (this.props.match.params.country && this.props.match.params.country !== "0") {
      country = this.props.match.params.country;
    }
    this.props.getAllBreeders(1, country);
    this.setState({country});
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log(this.props);
    if (prevProps.breeders.data !== this.props.breeders.data) {
      this.setState({
        breeders: this.props.breeders.data.breeders,
        total: this.props.breeders.data.total,
        pages: this.props.breeders.data.total_pages
      });
    }
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({activePage: pageNumber});
    this.props.getAllBreeders(pageNumber, this.state.country);
  };

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed">
              <Breadcrumb title="all_puppies" />
              <h2><FormattedMessage id="all_breeders" /></h2>
              <div className="puppies-content breeders-content">
                { this.props.breeders.loading &&
                  <div className="loader load-filters loader-filter-puppies">
                    <CircularProgress />
                  </div>
                }
                <ul>
                  { this.state.breeders.map (breeder => (
                    <li key={`breeder-${breeder.id}`}>
                      <ContactBreeder show={true} breeder={breeder} />
                    </li>
                  ))}                
                </ul>
                { this.state.pages > 0 &&
                  <div className="pagination">
                    <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  breeders: {
    data: state.breederReducer.data,
    loading: state.breederReducer.loading,
    error: state.breederReducer.error
  },
});

const mapDispatchToProps = {
  getAllBreeders,
};


export default connect(structuredSelector, mapDispatchToProps)(Breeders);
