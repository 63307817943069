import { SESSION_LOADING, SESSION_SUCCESS, SESSION_ERROR } from "../actions/session.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function sessionReducer(state = initialState, action) {
    switch (action.type) { 
        case SESSION_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case SESSION_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case SESSION_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        } 
        default: { 
            return state; 
        } 
    } 
 }