import React from 'react';
import { connect } from 'react-redux';
import { getBreed, cancelBreed } from '../actions/breed.action';
import { getCountries } from '../actions/country.action';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import PuppyItem from '../components/PuppyItem';

import Pagination from '@material-ui/lab/Pagination';
import { getPuppies } from '../actions/puppy.action';
import { Link } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="puppy-search-form">
      <div className="input-form">
        <TextField inputRef={register} name="name" size="small" label={intl.formatMessage({ id: 'search_by_name' })} variant="outlined" />
      </div>
      <div className="input-form">
        <Autocomplete
          loading={props.breederLoading}
          options={props.breeds}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="breed" {...params} size="small" label={intl.formatMessage({ id: 'breeds' })} variant="outlined" />}
        />
      </div>
      <div className="input-form">
        <Autocomplete
          loading={props.countryLoading}
          options={props.countries}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="country" {...params} size="small" label={intl.formatMessage({ id: 'countries' })} variant="outlined" />}
        />
      </div>
      <div className="input-form button-form">
        <Button type="submit" variant="contained" color="primary">
          <FormattedMessage id="search_puppies" />
        </Button>
      </div>
    </form>
  );
}

class Puppies extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      puppies: [],
      activePage: 1,
      total: 0,
      pages: 0,
      country: null,
      breed: null,
      countries: [],
      breeds: [],
      word: null
    }
  }

  componentDidMount = () => {
    let country = null;
    let breed = null;
    if (this.props.match.params.breed && this.props.match.params.breed !== "0") {
      breed = this.props.match.params.breed;
    }
    if (this.props.match.params.country && this.props.match.params.country !== "0") {
      country = this.props.match.params.country;
    }
    this.props.getPuppies(1, null, breed, country);
    this.setState({country, breed});

    this.props.getBreed();
    this.props.getCountries();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.puppy.data !== this.props.puppy.data) {
      this.setState({
        puppies: this.props.puppy.data.puppies,
        total: this.props.puppy.data.total,
        pages: this.props.puppy.data.total_pages
      });
    }

    if (prevProps.match.params.country !== this.props.match.params.country) {
      // this.setState({
      //   country: this.props.match.params.country
      // });
    }

    if (prevProps.match.params.breed !== this.props.match.params.breed) {
      // this.setState({
      //   breed: this.props.match.params.breed
      // });
    }

    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds
      });
    }

    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }
  }

  componentWillUnmount = () => {
    this.props.cancelBreed();
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({activePage: pageNumber});
    this.props.getPuppies(pageNumber, this.state.word, this.state.breed, this.state.country);
  };

  search = (data) => {
    let country = {id: ""};
    let breed = {id: ""};

    if (data.country) {
      country = this.state.countries.filter((item) => item.name === data.country)[0];
    }

    if (data.breed) {
      breed = this.state.breeds.filter((item) => item.name === data.breed)[0];
    }

    this.props.getPuppies(1, data.name, breed.id, country.id);
    this.setState({activePage: 1, word: data.name, breed: breed.id, country: country.id});
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed">
              <Breadcrumb title="all_puppies" />
              <h2><FormattedMessage id="all_puppies_for_sale" /></h2>
              <div>
                <Form 
                  breederLoading={this.props.breed.loading}
                  countryLoading={this.props.country.loading}
                  error={this.props.breed.error}
                  submit={(data) => this.search(data)}
                  countries={this.state.countries}
                  breeds={this.state.breeds}
                />
              </div>
              <div className="puppies-content">
                { this.props.puppy.loading &&
                  <div className="loader load-filters loader-filter-puppies">
                    <CircularProgress />
                  </div>
                }
                { (this.state.puppies && this.state.puppies.length === 0) &&
                  <div className="no-puppies">No puppies found <a href="/puppies">Check all puppies</a></div>
                }
                <ul>
                  { this.state.puppies.map (puppy => (
                    <li key={`puppie-${puppy.id}`}>
                      <PuppyItem 
                        id={puppy.id}
                        title={puppy.breed.name}
                        image={(puppy.images && puppy.images.length > 0 ) ? puppy.images[0].url : null}
                        price={puppy.price}
                        description={`${puppy.name && puppy.name} | ${puppy.country.name}`}
                      />
                    </li>
                  ))}                
                </ul>
                { this.state.pages > 0 &&
                  <div className="pagination">
                    <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  puppy: {
    data: state.puppyReducer.data,
    loading: state.puppyReducer.loading,
    error: state.puppyReducer.error
  },
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  },
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  }
});

const mapDispatchToProps = {
  getPuppies,
  getBreed,
  getCountries,
  cancelBreed
};


export default connect(structuredSelector, mapDispatchToProps)(Puppies);
