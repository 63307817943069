import Api from "../services/Api"

export const PUPPY_WANTED_LOADING = 'PUPPY_WANTED_LOADING';
export const PUPPY_WANTED_SUCCESS = 'PUPPY_WANTED_SUCCESS';
export const PUPPY_WANTED_ERROR = 'PUPPY_WANTED_ERROR';
export const PUPPY_WANTED_CREATE_LOADING = 'PUPPY_WANTED_CREATE_LOADING';
export const PUPPY_WANTED_CREATE_SUCCESS = 'PUPPY_WANTED_CREATE_SUCCESS';
export const PUPPY_WANTED_CREATE_ERROR = 'PUPPY_WANTED_CREATE_ERROR';
export const PUPPY_WANTED_DESTROY_LOADING = 'PUPPY_WANTED_DESTROY_LOADING';
export const PUPPY_WANTED_DESTROY_SUCCESS = 'PUPPY_WANTED_DESTROY_SUCCESS';
export const PUPPY_WANTED_DESTROY_ERROR = 'PUPPY_WANTED_DESTROY_ERROR';
export const PUPPY_WANTED_UPDATE_LOADING = 'PUPPY_WANTED_UPDATE_LOADING';
export const PUPPY_WANTED_UPDATE_SUCCESS = 'PUPPY_WANTED_UPDATE_SUCCESS';
export const PUPPY_WANTED_UPDATE_ERROR = 'PUPPY_WANTED_UPDATE_ERROR';
export const GET_PUPPY_WANTED_LOADING = 'GET_PUPPY_WANTED_LOADING';
export const GET_PUPPY_WANTED_SUCCESS = 'GET_PUPPY_WANTED_SUCCESS';
export const GET_PUPPY_WANTED_ERROR = 'GET_PUPPY_WANTED_ERROR';

export const getPuppyWantedSearch = (page, query) => dispatch => {

  dispatch({ type: PUPPY_WANTED_LOADING });
  if (!page) page = '';

  Api.getPuppyWantedSearch(page, query)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PUPPY_WANTED_SUCCESS, data }),
      error => dispatch({ type: PUPPY_WANTED_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};

export const getPuppyWanteds = (page) => dispatch => {

  dispatch({ type: PUPPY_WANTED_LOADING });
  if (!page) page = '';

  Api.getPuppyWanteds(page)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PUPPY_WANTED_SUCCESS, data }),
      error => dispatch({ type: PUPPY_WANTED_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};

export const getPuppyWanted = (id) => dispatch => {
   dispatch({ type: GET_PUPPY_WANTED_LOADING });

    Api.getPuppyWanted(id)
      .then(response => response.json())
      .then(
        data => dispatch({ type: GET_PUPPY_WANTED_SUCCESS, data }),
        error => dispatch({ type: GET_PUPPY_WANTED_ERROR, error: error.message || 'Unexpected Error!!!' })
      )
};

export const createPuppyWanted = (data) => dispatch => {
  dispatch({ type: PUPPY_WANTED_CREATE_LOADING });

  Api.createPuppyWanted(data)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PUPPY_WANTED_CREATE_SUCCESS, data }),
      error => dispatch({ type: PUPPY_WANTED_CREATE_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};

export const updatePuppyWanted = (data) => dispatch => {

  dispatch({ type: PUPPY_WANTED_UPDATE_LOADING });

   Api.updatePuppyWanted(data)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PUPPY_WANTED_UPDATE_SUCCESS, data }),
      error => dispatch({ type: PUPPY_WANTED_UPDATE_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};
//
export const destroyPuppyWanted = (id) => dispatch => {

  dispatch({ type: PUPPY_WANTED_DESTROY_LOADING });

   Api.destroyPuppyWanted(id)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PUPPY_WANTED_DESTROY_SUCCESS, data }),
      error => dispatch({ type: PUPPY_WANTED_DESTROY_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};
