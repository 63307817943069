import {
  STRIPE_LOADING,
  STRIPE_SUCCESS,
  STRIPE_ERROR,
  STRIPE_CREATE_LOADING,
  STRIPE_CREATE_SUCCESS,
  STRIPE_CREATE_ERROR
} from "../actions/stripe.action";

const initialState = {
 data: [],
 loading: false,
 error: ''
};

export default function stripeReducer(state = initialState, action) {
  switch (action.type) { 
    case STRIPE_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case STRIPE_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case STRIPE_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    }
    case STRIPE_CREATE_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case STRIPE_CREATE_SUCCESS: { 
      return {
        ...state, 
        data: action.data, 
        loading: false 
      }
    } 
    case STRIPE_CREATE_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
}