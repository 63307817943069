import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";

import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

class ContactBreeder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  fixUrl = (url) => {
    let letUrlSplited = url.split('://');

    if (letUrlSplited.length > 1) {
      return url;
    } else {
      return `http://${url}`;
    }
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render () {
    let url = '';
    if (this.props.breeder && this.props.breeder.site) {
      url = this.fixUrl(this.props.breeder.site);
    }
  
  	return (
      <div className="breeder-page">
        {this.props.breeder &&
          <>
            { this.props.breeder &&
              <h2>{this.props.breeder.breeder_name}</h2>              
            }
            { !this.props.breeder &&
              <h2>{this.props.breeder.first_name} {this.props.breeder.last_name}</h2>              
            }
            <div className="breeder-profile">
              { (this.props.breeder.image && this.props.breeder.image.url) &&
                <img src={this.props.breeder.image.url} height="150" />
              }
              <p>
                {this.props.breeder.about}
              </p>
            </div>
            <div className="more-breeder">
              { (this.props.breeder.country || this.props.breeder.state || this.props.breeder.city || this.props.breeder.street) &&
                <>
                  <h4><FormattedMessage id="breeder_address" /></h4>
                  <span><FormattedMessage id="located_at" />: </span>
                </>
              }
              { this.props.breeder.country &&
                <>
                  <span>{this.props.breeder.country.name}</span>
                </>
              }
              { this.props.breeder.state &&
                <>
                  <span> / </span>
                  <span>{this.props.breeder.state}</span>
                </>
              }
              { this.props.breeder.city &&
                <>
                  <span> / </span>
                  <span>{this.props.breeder.city}</span>
                </>
              }
              { this.props.breeder.street &&
                <>
                  <span> / </span>
                  <span>{this.props.breeder.street}</span>
                </>
              }
              { url !== '' &&
                <>
                  <span> / </span>
                  <a className="breeder-site" href={url} target="_blank"><FormattedMessage id="access_breeder_site" /></a>
                </>
              }
              { ( this.props.breeder.id && this.props.show ) &&
                <>
                  <span> / </span>
                  <Link className="breeder-site" to={`/breeder/${this.props.breeder.id}`}><FormattedMessage id="access_breeder_directory" /></Link>
                </>
              }
              { (this.props.breeder.phone || this.props.breeder.email) &&
                <div className="contact-breeder">
                  <div className="input-form button-form">
                    <Button onClick={this.handleClickOpen}><FormattedMessage id="contact_breeder" /></Button>
                  </div>
                </div>
              }
            </div>
          </>
        }
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            { (this.props.breeder && this.props.breeder.breeder) &&
              <>
                {this.props.breeder.breeder_name}
              </>
            }
            { (this.props.breeder && !this.props.breeder.breeder) &&
              <>
                {this.props.breeder.first_name} {this.props.breeder.last_name}
              </>
            }
          </DialogTitle>
          <DialogContent dividers>
            <Typography>              
              { (this.props.breeder && this.props.breeder.email) &&
                <>
                  <span><FormattedMessage id="email" />: </span>
                  {this.props.breeder.email}
                  <br />
                </>
              }
              { (this.props.breeder && this.props.breeder.phone) &&
                <>
                  <span><FormattedMessage id="phone" />: </span>
                  {this.props.breeder.phone}
                  <br />
                </>
              }
            </Typography>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default ContactBreeder;
