import { HOME_LOADING, HOME_SUCCESS, HOME_ERROR } from "../actions/home.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) { 
        case HOME_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case HOME_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case HOME_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        } 
        default: { 
            return state; 
        } 
    } 
 }