import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { getCountries } from '../actions/country.action';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

class BreedersCountries extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      countries: []
    }
  }

  componentDidMount = () => {
    this.props.getCountries(true);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed">
              <Breadcrumb title="dog_breeders_by_country" />
              <h2><FormattedMessage id="dog_breeders_by_country" /></h2>
              <div className="countries-content">
                <ul>
                  { this.state.countries.map(item => (
                    <li>
                      <Link to={`/breeders/${item.id}`}>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to={`/breeders`}>
                      <FormattedMessage id="other_countries" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  }
});

const mapDispatchToProps = {
  getCountries
};

export default connect(structuredSelector, mapDispatchToProps)(BreedersCountries);
