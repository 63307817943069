import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { createForgot } from '../actions/forgot.action';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <TextField inputRef={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} name="email" size="small" label={intl.formatMessage({ id: 'email' })} variant="outlined" />
        {errors.email && errors.email.type === "required" && <span className="error"><FormattedMessage id="email_is_required" /></span>}
        {errors.email && errors.email.type === "pattern" && <span className="error"><FormattedMessage id="email_is_not_valid" /></span>}
      </div>
      { props.error !== '' && 
        <div className="server-error">
          <FormattedMessage id="user_does_not_exists" />
        </div>
      }
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="send_me_the_instructions" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class Forgotten extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: false
    }
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.forgot.data !== this.props.forgot.data) {
      this.setState({message: true});
    }
  }

  onSubmit = data => {
    console.log(data);

  }
  
  process = (data) => {
    this.props.createForgot(data.email);
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="password_forgotten" />
              <div className="login-content">
                <div className="form-content">
                  <h2><FormattedMessage id="forgot_your_password" /></h2>
                  {this.state.message && 
                    <h3><FormattedMessage id="instrunction_was_sent_to_your_email" /></h3>
                  }
                  <Form 
                    loading={this.props.forgot.loading}
                    error={this.props.forgot.error}
                    submit={(data) => this.process(data)} 
                  />
                </div>
                <div className="form-links">
                  <ul>
                    <li>
                      <Link to="/login">
                        <FormattedMessage id="login" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/sign-up">
                        <FormattedMessage id="sign_up" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  forgot: {
    data: state.forgotReducer.data,
    loading: state.forgotReducer.loading,
    error: state.forgotReducer.error
  }
});

const mapDispatchToProps = {
  createForgot
};

export default connect(structuredSelector, mapDispatchToProps)(Forgotten);
