import React from 'react';
import { IntlProvider } from 'react-intl';
import en from './i18n/en.json';
import es from './i18n/es.json';
import pt from './i18n/pt.json';
import axios from 'axios';
import { Redirect, useParams, Route, Router } from "react-router-dom"

const contriesEs = ['ar', 'bo', 'cl', 'co', 'cr', 'do', 'ec', 'sv', 'gt', 'hn', 'mx', 'ni', 'pa', 'py', 'pe', 'pr', 'es', 'uy', 've'];
const contriesPt = ['br', 'pt'];
const permitedLangs = ['en', 'es', 'pt'];

export default class IntlProviderConfigured extends React.Component {
    state = {
        loading: true,
        lang: ''
    }

    componentDidMount() {
        let lang = window.location.pathname.split('/')[1];

        if (lang && permitedLangs.includes(lang)) {
            localStorage.setItem('lang', lang);
            this.setState({ lang: lang, loading: false });
        } else {
            this.getData();
        }
    }

    getData = async () => {
        let response = null;
        let lang = 'es';
        const langStorage = localStorage.getItem('lang');
        // if (!langStorage || langStorage === '') {
        //     try {
        //         response = await axios.get("http://ipinfo.io/json?token=598c00d2e891ed");
        //     } catch (e) {
        //         console.log(e);
        //     }
        //
        //     if (!response) {
        //         if (navigator.language.split('-')[0].includes(["en", "es", "pt"])) {
        //             lang = navigator.language.split('-')[0];
        //         }
        //     } else if (response.data) {
        //         if (response.data.country) {
        //             if (contriesEs.includes(response.data.country.toLowerCase())) {
        //                 lang = 'es';
        //             } else if (contriesPt.includes(response.data.country.toLowerCase())) {
        //                 lang = 'pt';
        //             }
        //         }
        //     }
        // } else {
        //     lang = langStorage;
        // }

        localStorage.setItem('lang', lang);

        this.setState({ lang: lang, loading: false })
    }

    render() {
        const locale = this.state.lang;
        const { children } = this.props;

        const translations = {
            'en': en,
            'es': es,
            'pt': pt
        };


        if(this.state.loading) return <div>...</div>

        return (
          <IntlProvider locale={locale} messages={translations[locale]}>
              {children}
          </IntlProvider>
        )
    }
}
