import { FORGOT_LOADING, FORGOT_SUCCESS, FORGOT_ERROR } from "../actions/forgot.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function forgotReducer(state = initialState, action) {
    switch (action.type) { 
        case FORGOT_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case FORGOT_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case FORGOT_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        } 
        default: { 
            return state; 
        } 
    } 
 }