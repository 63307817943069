import Api from "../services/Api"

export const KID_TASK_UPDATE_LOADING = 'KID_TASK_UPDATE_LOADING';
export const KID_TASK_UPDATE_SUCCESS = 'KID_TASK_UPDATE_SUCCESS';
export const KID_TASK_UPDATE_ERROR = 'KID_TASK_UPDATE_ERROR';

export const updateKidTask = (id, routineId, kidRoutineId, startTime, endTime) => dispatch => {

   dispatch({ type: KID_TASK_UPDATE_LOADING });

    Api.updateKidTask(id, routineId, kidRoutineId, startTime, endTime)
       .then(response => response.json())
       .then(
            data => dispatch({ type: KID_TASK_UPDATE_SUCCESS, data }),
            error => dispatch({ type: KID_TASK_UPDATE_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};