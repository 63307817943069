import {
  PRODUCT_LOADING,
  PRODUCT_SUCCESS,
  PRODUCT_ERROR,
  PRODUCT_CREATE_LOADING,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_ERROR,
  PRODUCT_DESTROY_LOADING,
  PRODUCT_DESTROY_SUCCESS,
  PRODUCT_DESTROY_ERROR,
  PRODUCT_UPDATE_LOADING,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_ERROR
} from "../actions/product.action";

const initialState = {
 data: [],
 loading: false,
 error: ''
};

export default function productReducer(state = initialState, action) {
  switch (action.type) { 
      case PRODUCT_LOADING: { 
          return { 
              ...state, 
              loading: true, 
              error:'' 
          }; 
      } 
      case PRODUCT_SUCCESS: { 
          return { 
              ...state, 
              data: action.data, 
              loading: false 
          } 
      } 
      case PRODUCT_ERROR: { 
          return { 
              ...state, 
              loading: false, 
              error: action.error 
          }; 
      }
      case PRODUCT_CREATE_LOADING: { 
          return { 
              ...state, 
              loading: true, 
              error:'' 
          }; 
      } 
      case PRODUCT_CREATE_SUCCESS: { 
          return { 
              ...state, 
              data: action.data, 
              loading: false 
          } 
      } 
      case PRODUCT_CREATE_ERROR: { 
          return { 
              ...state, 
              loading: false, 
              error: action.error 
          }; 
      } 
      case PRODUCT_DESTROY_LOADING: { 
          return { 
              ...state, 
              loading: true, 
              error:'' 
          }; 
      } 
      case PRODUCT_DESTROY_SUCCESS: { 
          return { 
              ...state, 
              data: action.data, 
              loading: false 
          } 
      } 
      case PRODUCT_DESTROY_ERROR: { 
          return { 
              ...state, 
              loading: false, 
              error: action.error 
          }; 
      }
      case PRODUCT_UPDATE_LOADING: { 
        return { 
            ...state, 
            loading: true, 
            error:'' 
        }; 
    } 
    case PRODUCT_UPDATE_SUCCESS: { 
        return { 
            ...state, 
            data: action.data, 
            loading: false 
        } 
    } 
    case PRODUCT_UPDATE_ERROR: { 
        return { 
            ...state, 
            loading: false, 
            error: action.error 
        }; 
    } 
      default: { 
          return state; 
      } 
  } 
}