import React from 'react';
import { connect } from 'react-redux';
import { getArticle } from '../actions/article.action';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import SanitizedHTML from 'react-sanitized-html';

class BuyerProtection extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    const page = this.props.match.path.replace('/:lang/', '');
    this.props.getArticle(page);
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            {this.props.article.data &&
              <div className="about-us">
                <Breadcrumb title="buyer_protection" />
                <h2>{ this.props.article.data.title }</h2>
                <div className="text-content">
                  <SanitizedHTML html={ this.props.article.data.text } />
                </div>
              </div>
            }
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  article: {
    data: state.articleReducer.data,
    loading: state.articleReducer.loading,
    error: state.articleReducer.error
  }
});

const mapDispatchToProps = {
  getArticle
};

export default connect(structuredSelector, mapDispatchToProps)(BuyerProtection);
