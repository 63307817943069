import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from 'redux-thunk';
import sessionReducer from './reducers/session.reducer';
import registrationReducer from './reducers/registration.reducer';
import forgotReducer from './reducers/forgot.reducer';
import kidRoutineReducer from './reducers/kidRoutine.reducer';
import kidTaskReducer from './reducers/kidTask.reducer';
import articleReducer from './reducers/article.reducer';
import breedReducer from './reducers/breed.reducer';
import categoryReducer from './reducers/category.reducer';
import countryReducer from './reducers/country.reducer';
import productReducer from './reducers/product.reducer';
import puppyReducer from './reducers/puppy.reducer';
import puppyWantedReducer from './reducers/puppy_wanted.reducer';
import profileReducer from './reducers/profile.reducer';
import breederReducer from './reducers/breeder.reducer';
import stripeReducer from './reducers/stripe.reducer';
import homeReducer from './reducers/home.reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
	sessionReducer,
	kidRoutineReducer,
	kidTaskReducer,
	registrationReducer,
	forgotReducer,
	articleReducer,
	breedReducer,
	categoryReducer,
	countryReducer,
	productReducer,
	puppyReducer,
	puppyWantedReducer,
	profileReducer,
	breederReducer,
	stripeReducer,
	homeReducer
});

export default function configureStore() {
	const store = createStore(
		rootReducer,
		composeWithDevTools(
			applyMiddleware(thunk)
		)
	);
	return store;
}
