import Api from "../services/Api"

export const BREEDER_LOADING = 'BREEDER_LOADING';
export const BREEDER_SUCCESS = 'BREEDER_SUCCESS';
export const BREEDER_ERROR = 'BREEDER_ERROR';
export const GET_BREEDER_LOADING = 'GET_BREEDER_LOADING';
export const GET_BREEDER_SUCCESS = 'GET_BREEDER_SUCCESS';
export const GET_BREEDER_ERROR = 'GET_BREEDER_ERROR';

export const getBreeders = (id, page) => dispatch => {

   if (!page) page = '';

   dispatch({ type: BREEDER_LOADING });
      Api.getBreeders(id, page)
         .then(response => response.json())
         .then(
          data => dispatch({ type: BREEDER_SUCCESS, data }),
          error => dispatch({ type: BREEDER_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
};

export const getAllBreeders = (page, country) => dispatch => {

   if (!page) page = '';
   if (!country) country = '';

   dispatch({ type: GET_BREEDER_LOADING });
      Api.getAllBreeders(page, country)
         .then(response => response.json())
         .then(
          data => dispatch({ type: GET_BREEDER_SUCCESS, data }),
          error => dispatch({ type: GET_BREEDER_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
};
