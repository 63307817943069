import Api from "../services/Api"

export const COUNTRY_LOADING = 'COUNTRY_LOADING';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_ERROR = 'COUNTRY_ERROR';

export const getCountries = (list) => dispatch => {

   if (!list) list = '';

   dispatch({ type: COUNTRY_LOADING });

    Api.getCountries(list)
       .then(response => response.json())
       .then(
            data => dispatch({ type: COUNTRY_SUCCESS, data }),
            error => dispatch({ type: COUNTRY_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};