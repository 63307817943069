import React from 'react';
import uuid from 'react-uuid';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getPuppyWantedSearch } from '../actions/puppy_wanted.action';
import { getCountries } from '../actions/country.action';
import { getBreed } from '../actions/breed.action';
import Skeleton from 'react-loading-skeleton';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PuppyWantedItem from '../components/PuppyWantedItem';
import Breadcrumb from '../components/Breadcrumb';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <Autocomplete
          loading={props.countryLoading}
          options={props.countries}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="country" {...params} size="small" label={intl.formatMessage({ id: 'countries' })} variant="outlined" />}
        />
      </div>
      <div className="input-form">
        <Autocomplete
          loading={props.breedLoading}
          options={props.breeds}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={
            (params) => <TextField
              inputRef={register}
              name="breed"
              {...params}
              size="small"
              label={intl.formatMessage({ id: 'breed' })}
              variant="outlined"
            />
          }
        />
      </div>
      <div className="input-form button-form">
        <Button type="submit" variant="contained" color="primary">
          <FormattedMessage id="puppy_wanted_search" />
        </Button>
      </div>
    </form>
  );
}

class FindPuppyWanted extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      puppyWanteds: [],
      activePage: 1,
      total: 0,
      pages: 0,
      countries: [],
      breeds: [],
      country: "",
      breed: "",
      word: "",
      language: "",
      open: false,
      data: null,
      user: null
    }
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  componentDidMount = () => {
    this.props.getPuppyWantedSearch(1);
    this.props.getCountries();
    this.props.getBreed();

    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    if (credentials) {
      this.setState({
        user: credentials
      });
    }
  }

  componentWillUnmount = () => {
    // this.props.cancelBreed();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.puppyWanted.data !== this.props.puppyWanted.data) {
      this.setState({
        puppyWanteds: this.props.puppyWanted.data.puppy_wanteds,
        total: this.props.puppyWanted.data.total,
        pages: this.props.puppyWanted.data.total_pages
      });
    }

    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }

    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds
      });
    }
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({activePage: pageNumber});
    let query = {}

    if (this.state.country) {
      query.country_id = this.state.country;
    }

    if (this.state.breed) {
      query.breed_id = this.state.breed;
    }

    if (this.state.language) {
      query.preferent_language = this.state.language;
    }

    this.props.getPuppyWantedSearch(pageNumber, query);
  };

  search = (data) => {
    let query = {}

    if (data.country) {
      query.country_id = this.state.countries.filter((item) => item.name === data.country)[0]?.id;
    }

    if (data.breed) {
      query.breed_id = this.state.breeds.filter((item) => item.name === data.breed)[0]?.id;
    }

    this.props.getPuppyWantedSearch(1, query);

    this.setState({
      activePage: 1,
      language: query.preferent_language,
      breed: query.breed_id,
      country: query.country_id
    })
  }

  handleForSale = (id) => {
    this.props.history.push(`/puppies/0/${id}`)
  }

  render () {
  	return (
      <>
        <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}></DialogTitle>
          <DialogContent dividers>
            <Typography>              
              {this.state.data && this.state.user && this.state.user.subscription_status === 'active' && (
                <>
                  <strong>{this.state.data.first_name} {this.state.data.last_name}</strong><br />
                  <span><FormattedMessage id="email" />: {this.state.data.email}</span><br />
                  <span><FormattedMessage id="phone" />: {this.state.data.phone}</span><br />
                  <span><FormattedMessage id="city" />: {this.state.data.city}</span><br />
                  <span><FormattedMessage id="puppy_wanted_how_much" /></span><br />
                  <span>{this.state.data.how_much}</span><br />
                </>
              )}
              {this.state.data && !this.state.user && (
                <span>Please login to be see user contact.</span>
              )}
              {this.state.data && this.state.user && this.state.user.subscription_status !== 'active' && (
                <span>Please subscribe to to see user contact.</span>
              )}
            </Typography>
          </DialogContent>
        </Dialog>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed">
              <Breadcrumb title="find_my_breed" />
              <h2><FormattedMessage id="puppy_wanted" /></h2>
              <div className="breed-content">
                <div className="breed-filters">
                  <Form
                    breedLoading={this.props.breed.loading}
                    countryLoading={this.props.country.loading}
                    loading={this.props.puppyWanted.loading}
                    error={this.props.puppyWanted.error}
                    submit={(data) => this.search(data)}
                    countries={this.state.countries}
                    breeds={this.state.breeds}
                  />
                </div>
                <div className="whole-cotent">
                  { this.state.pages > 0 &&
                    <div className="pagination">
                      <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                      { this.props.puppyWanted.loading &&
                        <CircularProgress />
                      }
                    </div>
                  }
                  <div className="find-my-breed-list">
                    { this.props.puppyWanted.loading &&
                      <div className="loader load-breed">
                        <CircularProgress />
                      </div>
                    }
                    { ( !this.props.puppyWanted.loading && this.state.puppyWanteds.length === 0 ) &&
                      <p className="no-result-found"><FormattedMessage id="no_results" /></p>
                    }
                    {this.state.puppyWanteds.length > 0 &&
                      <ul>
                        { this.state.puppyWanteds.map(item => (
                          <li key={uuid()}>
                            <PuppyWantedItem
                              data={item}
                              onHandle={(data) => {
                                this.setState({data})
                                this.handleClickOpen()
                              }}
                            />
                          </li>
                        ))}
                      </ul>
                    }
                  </div>
                  { this.state.pages > 0 &&
                    <div className="pagination">
                      <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  puppyWanted: {
    data: state.puppyWantedReducer.data,
    loading: state.puppyWantedReducer.loading,
    error: state.puppyWantedReducer.error
  },
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  },
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  }
});

const mapDispatchToProps = {
  getPuppyWantedSearch,
  getCountries,
  getBreed
};

export default connect(structuredSelector, mapDispatchToProps)(FindPuppyWanted);
