import React from "react";
import imagePlaceholder from 'images/dog_placeholder.png';
import { Link } from 'react-router-dom';

export default function PuppyItem({ id, title, image, price, description }) {
  return (
    <div className="puppies-item">
      <Link to={`/puppy/${id}`}>
        <h3>{title}</h3>
        { image && <img src={image} /> }
        { !image && <img src={imagePlaceholder} /> }
        <span>{price}</span>
        <p>{description}</p>
      </Link>
    </div>
  );
}

