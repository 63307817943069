import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

class Breadcrumb extends React.Component {
  constructor(props){
    super(props);
  }

  render () {
  	return (
      <div className="breadcrumb">
        <Link to="/"><FormattedMessage id="home" /></Link>
        <span className="space">/</span>
        <span><FormattedMessage id={this.props.title} /></span>
      </div>
    );
  }
}

export default Breadcrumb;
