import {
    KID_ROUTINE_LOADING,
    KID_ROUTINE_SUCCESS,
    KID_ROUTINE_ERROR,
    KID_ROUTINE_UPDATE_LOADING,
    KID_ROUTINE_UPDATE_SUCCESS,
    KID_ROUTINE_UPDATE_ERROR
} from "../actions/kidRoutine.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function kidRoutineReducer(state = initialState, action) {
    switch (action.type) { 
        case KID_ROUTINE_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case KID_ROUTINE_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case KID_ROUTINE_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        }
        case KID_ROUTINE_UPDATE_LOADING: { 
            return { 
                ...state, 
                loading: true, 
                error:'' 
            }; 
        } 
        case KID_ROUTINE_UPDATE_SUCCESS: { 
            return { 
                ...state, 
                data: action.data, 
                loading: false 
            } 
        } 
        case KID_ROUTINE_UPDATE_ERROR: { 
            return { 
                ...state, 
                loading: false, 
                error: action.error 
            }; 
        } 
        default: { 
            return state; 
        } 
    } 
 }