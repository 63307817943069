import React from 'react';
import { connect } from 'react-redux';
import { getBreed, cancelBreed } from '../actions/breed.action';
import { getCountries } from '../actions/country.action';
import { getCategories } from '../actions/category.action';
import Skeleton from 'react-loading-skeleton';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BreedItem from '../components/BreedItem';
import Breadcrumb from '../components/Breadcrumb';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <TextField inputRef={register} name="name" size="small" label={intl.formatMessage({ id: 'search_by_name' })} variant="outlined" />
      </div>
      <div className="input-form">
        <Autocomplete
          loading={props.countryLoading}
          options={props.countries}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="country" {...params} size="small" label={intl.formatMessage({ id: 'countries' })} variant="outlined" />}
        />
      </div>
      <div className="input-form">
        <Autocomplete
          loading={props.categoryLoading}
          options={props.categories}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="category" {...params} size="small" label={intl.formatMessage({ id: 'group' })} variant="outlined" />}
        />
      </div>
      <div className="input-form button-form">
        <Button type="submit" variant="contained" color="primary">
          <FormattedMessage id="search_breeds" />
        </Button>
      </div>
    </form>
  );
}

class FindMyBreed extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      breeds: [],
      activePage: 1,
      total: 0,
      pages: 0,
      countries: [],
      categories: [],
      country: "",
      category: "",
      word: ""
    }
  }

  componentDidMount = () => {
    this.props.getBreed(1);
    this.props.getCountries();
    this.props.getCategories();
  }

  componentWillUnmount = () => {
    this.props.cancelBreed();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds,
        total: this.props.breed.data.total,
        pages: this.props.breed.data.total_pages
      });
    }

    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }

    if (prevProps.category.data !== this.props.category.data) {
      this.setState({
        categories: this.props.category.data.categories
      });
    }
  }

  handlePageChange = (event, pageNumber) => {
    this.setState({activePage: pageNumber});
    this.props.getBreed(pageNumber, this.state.word, this.state.country, this.state.category);
  };

  search = (data) => {
    let category = {id: ""};
    let country = {id: ""};

    if (data.category) {
      category = this.state.categories.filter((item) => item.name === data.category)[0];
    }

    if (data.country) {
      country = this.state.countries.filter((item) => item.name === data.country)[0];
    }

    this.props.getBreed(1, data.name, country.id,  category.id,);
    this.setState({activePage: 1, word: data.name, category: category.id, country: country.id});
  }

  handleForSale = (id) => {
    this.props.history.push(`/puppies/0/${id}`)
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed">
              <Breadcrumb title="find_my_breed" />
              <h2><FormattedMessage id="find_my_breed" /></h2>
              <div className="breed-content">
                <div className="breed-filters">
                  <Form
                    categoryLoading={this.props.category.loading}
                    countryLoading={this.props.country.loading}
                    loading={this.props.breed.loading}
                    error={this.props.breed.error}
                    submit={(data) => this.search(data)}
                    countries={this.state.countries}
                    categories={this.state.categories}
                  />
                </div>
                <div className="whole-cotent">
                  { this.state.pages > 0 &&
                    <div className="pagination">
                      <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                      { this.props.breed.loading &&
                        <CircularProgress />
                      }
                    </div>
                  }
                  <div className="find-my-breed-list">
                    { this.state.breeds.length === 0 &&
                      <div className="loader load-breed">
                        <CircularProgress />
                      </div>
                    }
                    {this.state.breeds.length > 0 &&
                      <ul>
                        { this.state.breeds.map(item => (
                          <li key={`breed_${item.id}`}>
                            <BreedItem
                              image={item.avatar?.url}
                              name={item.name}
                              id={item.id}
                              category={item.category}
                              country={item.country}
                              color={item.color}
                              weight={item.weight}
                              height={item.height}
                              playfulness={item.playfulness}
                              trainability={item.trainability}
                              watch_dog_ability={item.watch_dog_ability}
                              exercise_needs={item.exercise_needs}
                              dog_friendly={item.dog_friendly}
                              onHandleForSale={this.handleForSale}
                            />
                          </li> 
                        ))}                   
                      </ul>
                    }
                  </div>
                  { this.state.pages > 0 &&
                    <div className="pagination">
                      <Pagination count={this.state.pages} page={this.state.activePage} onChange={this.handlePageChange} variant="outlined" shape="rounded" />
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  },
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  },
  category: {
    data: state.categoryReducer.data,
    loading: state.categoryReducer.loading,
    error: state.categoryReducer.error
  }
});

const mapDispatchToProps = {
  getBreed,
  getCountries,
  getCategories,
  cancelBreed
};

export default connect(structuredSelector, mapDispatchToProps)(FindMyBreed);
