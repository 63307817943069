import {
  PUPPY_LOADING,
  PUPPY_SUCCESS,
  PUPPY_ERROR,
  GET_PUPPY_LOADING,
  GET_PUPPY_SUCCESS,
  GET_PUPPY_ERROR
} from "../actions/puppy.action";

const initialState = {
  data: [],
  loading: false,
  error: ''
};

export default function puppyReducer(state = initialState, action) {
  switch (action.type) { 
    case PUPPY_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case PUPPY_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case PUPPY_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    }
    case GET_PUPPY_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case GET_PUPPY_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case GET_PUPPY_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
 }
