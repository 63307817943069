import Api from "../services/Api"

export const SESSION_LOADING = 'SESSION_LOADING';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_ERROR = 'SESSION_ERROR';

export const createSession = (email, password) => dispatch => {

   dispatch({ type: SESSION_LOADING });

    Api.createSession(email, password)
       .then(response => response.json())
       .then(
            data => dispatch({ type: SESSION_SUCCESS, data }),
            error => dispatch({ type: SESSION_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};