import Api from "../services/Api"

export const STRIPE_LOADING = 'STRIPE_LOADING';
export const STRIPE_SUCCESS = 'STRIPE_SUCCESS';
export const STRIPE_ERROR = 'STRIPE_ERROR';
export const STRIPE_CREATE_LOADING = 'STRIPE_CREATE_LOADING';
export const STRIPE_CREATE_SUCCESS = 'STRIPE_CREATE_SUCCESS';
export const STRIPE_CREATE_ERROR = 'STRIPE_CREATE_ERROR';

export const getPrices = () => dispatch => {

   dispatch({ type: STRIPE_LOADING });
    Api.getPrices()
      .then(response => response.json())
      .then(
        data => dispatch({ type: STRIPE_SUCCESS, data }),
        error => dispatch({ type: STRIPE_ERROR, error: error.message || 'Unexpected Error!!!' })
      )
};

 export const createStripeSession = (plan) => dispatch => {

  if (!plan) plan = '';

  dispatch({ type: STRIPE_CREATE_LOADING });

   Api.createStripeSession(plan)
    .then(response => response.json())
    .then(
      data => dispatch({ type: STRIPE_CREATE_SUCCESS, data }),
      error => dispatch({ type: STRIPE_CREATE_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};
