import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";

export default function CountrySelect({ onChange: ignored, control, options, label, name, loading, callback, required = true }) {
  return (
    <Controller
      rules={{ required: required }}
      as={
        <Autocomplete
          loading={loading}
          options={options}
          getOptionLabel={option => option.name ? option.name : ""}
          renderOption={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label={label}
              variant="outlined"
              size="small"
            />
          )}
        />
      }
      onChange={([, data]) => {
        if (callback) {
          callback(data)
        }
        return data
      }}
      name={name}
      control={control}
    />
  );
}

