import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import { createSession } from '../../actions/session.action';
import { useForm } from 'react-hook-form';
import { Link } from "react-router-dom";


function Form(props) {
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-input">
        <label>E-mail</label>
        <input name="email" ref={register} type="text" />
      </div>
      <div className="form-input">
        <label>Password</label>
        <input name="password" ref={register} type="password" />
      </div>
      <div className="form-input">
        <button type="submit" className="button-submit">Login</button>
      </div>
    </form>
  );
}

class TaskLogin extends React.Component {
  
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.session.data !== this.props.session.data) {
      localStorage.setItem('taskCredentials', JSON.stringify(this.props.session.data));
      this.props.history.push('/task/kid/1');
    }
  }

  onSubmit = data => {
    console.log(data);
  }
  
  login = (data) => {
    this.props.createSession(data.email, data.password);
  }

  render () {
    
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="login" />
              <div className="login-content">
                <div className="form-content">
                  <h2>Login</h2>
                  <Form submit={(data) => this.login(data)} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  session: {
    data: state.sessionReducer.data,
    loading: state.sessionReducer.loading,
    error: state.sessionReducer.error
  }
});

const mapDispatchToProps = {
  createSession
};

export default connect(structuredSelector, mapDispatchToProps)(TaskLogin);
