import Api from "../services/Api"

export const FORGOT_LOADING = 'FORGOT_LOADING';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR = 'FORGOT_ERROR';

export const createForgot = (email) => dispatch => {

   dispatch({ type: FORGOT_LOADING });

    Api.createForgot(email)
       .then(response => response.json())
       .then(
            data => dispatch({ type: FORGOT_SUCCESS, data }),
            error => dispatch({ type: FORGOT_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};