import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PuppyItem from '../components/PuppyItem';
import { getHome } from '../actions/home.action';
import { getBreed, cancelBreed } from '../actions/breed.action';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import BreedItem from '../components/BreedItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="puppy-search-form">
      <div className="input-form">
        <Autocomplete
          loading={props.loading}
          options={props.breeds}
          getOptionLabel={(option) => option.name ? option.name : ''}
          renderInput={(params) => <TextField inputRef={register} name="breed" {...params} size="small" label={intl.formatMessage({ id: 'search_by_sell' })} variant="outlined" />}
        />
      </div>
      <div className="input-form button-form">
        <Button type="submit" variant="contained" color="primary">
          <FormattedMessage id="search_puppies" />
        </Button>
      </div>
    </form>
  );
}


class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      puppies: [],
      breeds: [],
      breed: null
    }
  }

  componentDidMount = () => {
    this.props.getHome();
    this.props.getBreed();
  }

  componentWillUnmount = () => {
    this.props.cancelBreed();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.home.data !== this.props.home.data) {
      const puppies = this.props.home.data.puppies;
      const breed = this.props.home.data.breed;
      this.setState({
        puppies: puppies,
        breed: breed
      });
    }

    if (prevProps.breed.data !== this.props.breed.data) {
      this.setState({
        breeds: this.props.breed.data.breeds
      });
    }
  }

  handleForSale = (id) => {
    this.props.history.push(`/puppies/0/${id}`)
  }

  search = (data) => {
    let breed = {id: ""};
    if (data.breed) {
      breed = this.state.breeds.filter((item) => item.name === data.breed)[0];
    }
    this.props.history.push(`/puppies/0/${breed.id}`)
  }
  
  render () {

    return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="banner-menu-space"></div>
          <div className="home-top">
            <div className="content">
              <h2><FormattedMessage id="puppies_for_sale" /></h2>
              <Form 
                loading={this.props.breed.loading}
                submit={(data) => this.search(data)}
                breeds={this.state.breeds}
              />
            </div>
          </div>
          <div className="content"> 
            <div className="home-breed">            
              <h2>{ this.state.breed && this.state.breed.name}</h2>
              { this.state.breed &&
                <BreedItem
                  image={this.state.breed.avatar.url}
                  name={this.state.breed.name}
                  id={this.state.breed.id}
                  category={this.state.breed.category}
                  country={this.state.breed.country}
                  color={this.state.breed.color}
                  weight={this.state.breed.weight}
                  height={this.state.breed.height}
                  playfulness={this.state.breed.playfulness}
                  trainability={this.state.breed.trainability}
                  watch_dog_ability={this.state.breed.watch_dog_ability}
                  exercise_needs={this.state.breed.exercise_needs}
                  dog_friendly={this.state.breed.dog_friendly}
                  onHandleForSale={this.handleForSale}
                />
              }
            </div>
          </div>
          <div className="content">            
            <div className="puppies-content puppies-home">
              <h2><FormattedMessage id="best_puppies_for_sale" /></h2>
              <ul>
                { this.state.puppies.map (puppy => (
                  <li key={`puppie-${puppy.id}`}>
                    <PuppyItem 
                      id={puppy.id}
                      title={puppy.breed.name}
                      image={(puppy.images && puppy.images.length > 0 ) ? puppy.images[0].url : null}
                      price={puppy.price}
                      description={`${puppy.name && puppy.name} | ${puppy.country.name}`}
                    />
                  </li>
                ))}                
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  home: {
    data: state.homeReducer.data,
    loading: state.homeReducer.loading,
    error: state.homeReducer.error
  },
  breed: {
    data: state.breedReducer.data,
    loading: state.breedReducer.loading,
    error: state.breedReducer.error
  }
});

const mapDispatchToProps = {
  getHome,
  getBreed,
  cancelBreed
};

export default connect(structuredSelector, mapDispatchToProps)(Home);
