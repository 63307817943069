export const ApiUrl = '/api/v1';

let controller;

export default class Api {

  static createSession(email, password) {
    const uri = `${ApiUrl}/sessions`;
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: password})
    });
  }

  static createRegistration(email, password, confirm_passord) {
    const uri = `${ApiUrl}/registrations`;
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, password: password, password_confirmation: confirm_passord})
    });
  }

  static createForgot(email) {
    const uri = `${ApiUrl}/reset_passwords`;
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email})
    });
  }

  static getKidRoutine(routineId) {
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    const uri = `${ApiUrl}/kids_routines/${routineId}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static updateKidRoutine(id, routineId, startTime, endTime) {
    const uri = `${ApiUrl}/kids_routines/${id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      },
      body: JSON.stringify({
        routine_id: routineId,
        start_date: startTime,
        end_date: endTime
      })
    });
  }

  static updateKidTask(id, routineId, kidRoutineId, startTime, endTime) {
    const uri = `${ApiUrl}/kids_tasks/${id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      },
      body: JSON.stringify({
        routine_id: routineId,
        kid_routine_id: kidRoutineId,
        start_date: startTime,
        end_date: endTime
      })
    });
  }

  static getArticle(tag) {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/articles?tag=${tag}&language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getBreed(page, q, category, country) {
    controller = new AbortController();
    const { signal } = controller;
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/breeds?page=${page}&q=${q}&country=${category}&category=${country}&language=${langStorage}`;
    return fetch(uri, {
      signal: signal,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getSignal() {
    return controller;
  }

  static getHome() {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/homes?language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getCategories() {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/categories?language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getCountries(list) {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/countries?language=${langStorage}&list=${list}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getProducts(page) {
    const langStorage = localStorage.getItem('lang');
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    const uri = `${ApiUrl}/puppies?page=${page}&language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static createProduct(data) {
    const form = new FormData()
    if (data.images.length > 0) {
      data.images.map((item) => {
        form.append('images[]', item)
      });
    }
    form.append('sell', data.sell)
    form.append('name', data.name)
    form.append('breed_id', data.breed_id)
    form.append('country_id', data.country_id)
    form.append('gender', data.gender)
    form.append('description', data.description)
    form.append('video', data.video)
    form.append('price', data.price)
    form.append('born', data.born)
    form.append('language', data.language)
    form.append('shipping_area', data.shipping_area)
    form.append('champion_bloodlines', data.champion_bloodlines ? true : false)
    form.append('champion_sired', data.champion_sired ? true : false)
    form.append('show_potential', data.show_potential ? true : false)
    form.append('shipping_included', data.shipping_included ? true : false)
    form.append('registered_registerable', data.registered_registerable ? true : false)
    form.append('veterinarian_examination', data.veterinarian_examination ? true : false)
    form.append('health_certificate', data.health_certificate ? true : false)
    form.append('health_guarantee', data.health_guarantee ? true : false)
    form.append('pedigree', data.pedigree ? true : false)
    form.append('travel_crate', data.travel_crate ? true : false)

    const uri = `${ApiUrl}/puppies`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'POST',
      headers: {
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      },
      body: form
    });
  }

  static updateProduct(data) {
    const form = new FormData()
    if (data.images.length > 0) {
      data.images.map((item) => {
        form.append('images[]', item)
      });
    }
    form.append('name', data.name)
    form.append('breed_id', data.breed_id)
    form.append('country_id', data.country_id)
    form.append('gender', data.gender)
    form.append('description', data.description)
    form.append('video', data.video)
    form.append('price', data.price)
    form.append('born', data.born)
    form.append('language', data.language)
    form.append('shipping_area', data.shipping_area)
    form.append('champion_bloodlines', data.champion_bloodlines)
    form.append('champion_sired', data.champion_sired)
    form.append('show_potential', data.show_potential)
    form.append('shipping_included', data.shipping_included)
    form.append('registered_registerable', data.registered_registerable)
    form.append('veterinarian_examination', data.veterinarian_examination)
    form.append('health_certificate', data.health_certificate)
    form.append('health_guarantee', data.health_guarantee)
    form.append('pedigree', data.pedigree)
    form.append('travel_crate', data.travel_crate)


    const uri = `${ApiUrl}/puppies/${data.id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'PUT',
      headers: {
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      },
      body: form
    });
  }

  static destroyProduct(id) {
    const uri = `${ApiUrl}/puppies/${id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  // puppy_wanted
  static createPuppyWanted(data) {
    const uri = `${ApiUrl}/puppy_wanteds`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'POST',
      headers: {
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  }

  static updatePuppyWanted(data) {
    const uri = `${ApiUrl}/puppy_wanteds/${data.id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'PUT',
      headers: {
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  }

  static getPuppyWantedSearch(page, query) {
    const langStorage = localStorage.getItem('lang');
    const queryString = new URLSearchParams(query);
    const uri = `${ApiUrl}/puppy_wanteds/search?page=${page}&language=${langStorage}&${queryString.toString()}`;

    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
  }

  static getPuppyWanteds(page) {
    const langStorage = localStorage.getItem('lang');
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    const uri = `${ApiUrl}/puppy_wanteds?page=${page}&language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static getPuppyWanted(id) {
    const uri = `${ApiUrl}/puppy_wanteds/${id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static destroyPuppyWanted(id) {
    const uri = `${ApiUrl}/puppy_wanteds/${id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static getAllBreeders(page, country) {
    const uri = `${ApiUrl}/breeders?page=${page}&country=${country}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getBreeders(id, page) {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/breeders/${id}?page=${page}&language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getPuppies(page, q, breed, country) {
    const langStorage = localStorage.getItem('lang');
    const uri = `${ApiUrl}/puppies-for-sale?page=${page}&q=${q}&country=${country}&breed=${breed}&language=${langStorage}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getPuppy(id) {
    const uri = `${ApiUrl}/puppies-for-sale/${id}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  static getProfile(id) {
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    const uri = `${ApiUrl}/profiles/${id}`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static updateProfile(data) {
    const form = new FormData()
    if (data.image) {
      form.append('image', data.image)
    }
    form.append('breeder', data.breeder)
    form.append('breeder_name', data.breeder_name)
    form.append('city', data.city)
    form.append('last_name', data.last_name)
    form.append('postal_code', data.postal_code)
    form.append('site', data.site)
    form.append('state', data.state)
    form.append('street', data.street)
    form.append('phone', data.phone)
    form.append('first_name', data.first_name)
    form.append('country_id', data.country_id)
    form.append('about', data.about)

    const uri = `${ApiUrl}/profiles/${data.id}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'PUT',
      headers: {
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      },
      body: form
    });
  }

  static getPrices() {
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    const uri = `${ApiUrl}/stripes`;
    return fetch(uri, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }

  static createStripeSession(plan) {
    const uri = `${ApiUrl}/stripes?plan=${plan}`;
    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    return fetch(uri, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-User-Email': credentials.email,
        'X-User-Token': credentials.authentication_token
      }
    });
  }
}
