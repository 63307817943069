import React from "react";
import uuid from 'react-uuid';
import Rating from '@material-ui/lab/Rating';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

export default function PuppyWantedItem({data, onHandle}) {
  return (
    <div className="find-my-breed-item">
      <div className="breed-list-content">
        <h3>{data.breed.name}</h3>
        <p>
          {data.why}<br />
          <FormattedMessage id="puppy_wanted_item_gender" />: {data.gender}<br />
          <FormattedMessage id="puppy_wanted_item_property" />: {data.property_type}<br />
          <FormattedMessage id="puppy_wanted_item_country" />: {data.country.name}<br />
          <FormattedMessage id="puppy_wanted_item_preferred_delivery" />: {data.preferred_delivery}<br />
          <FormattedMessage id="puppy_wanted_item_state" />: {data.state}<br />
          <FormattedMessage id="puppy_wanted_how_much" />: {data.how_much}<br />
          <FormattedMessage id="puppy_wanted_item_children" />: <FormattedMessage id="total" /> {data.number_of_children} {", "}
          {data.puppy_wanted_children.map((it) => (
            <span key={uuid()}>
              <span><FormattedMessage id="age_of" /> {it.age}</span>{" "}
            </span>
          ))}
          <br />
          <FormattedMessage id="puppy_wanted_item_dogs" />: <FormattedMessage id="total" /> {data.number_of_dogs} {", "}
          {data.puppy_wanted_dogs.map((it) => (
            <span key={uuid()}>
              <span><FormattedMessage id="breeds" /> {it.breed.name}</span>{" "}
            </span>
          ))}
        </p>
      </div>
      <div className="breed-box">
        <div className="input-form button-form">
          <Button className="btn" onClick={() => onHandle(data)}>
            <FormattedMessage id="breeder_address" />
          </Button>
        </div>
      </div>
    </div>
  );
}
