import { CATEGORY_LOADING, CATEGORY_SUCCESS, CATEGORY_ERROR } from "../actions/category.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) { 
    case CATEGORY_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case CATEGORY_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case CATEGORY_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
 }