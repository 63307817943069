import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import ContactBreeder from '../components/ContactBreeder';
import PuppyItem from '../components/PuppyItem';
import { getBreeders } from '../actions/breeder.action';

class Breeder extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      puppies: [],
      breeder: null
    }
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.startComponent();
    }
  }

  startComponent = () => {
    const id = this.props.match.params.id;
    this.props.getBreeders(id, 1);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.breeder.data !== this.props.breeder.data) {
      this.setState({
        breeder: this.props.breeder.data,
        puppies: this.props.breeder.data.puppies
      });
    }
  }

  render () {
    return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="find-my-breed breeder-page">
              <Breadcrumb title="breeder" />
              <ContactBreeder breeder={this.state.breeder} />
              <div className="puppies-content puppies-home puppies-breeder">
                <h2>My puppies for sale</h2>
                <ul>
                  { this.state.puppies.map (puppy => (
                    <li key={`puppie-${puppy.id}`}>
                      <PuppyItem 
                        id={puppy.id}
                        title={puppy.breed.name}
                        image={(puppy.images && puppy.images.length > 0 ) ? puppy.images[0].url : null}
                        price={puppy.price}
                        description={`${puppy.name && puppy.name} | ${puppy.country.name}`}
                      />
                    </li>
                  ))}                
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  breeder: {
    data: state.breederReducer.data,
    loading: state.breederReducer.loading,
    error: state.breederReducer.error
  }
});

const mapDispatchToProps = {
  getBreeders
};

export default connect(structuredSelector, mapDispatchToProps)(Breeder);
