import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LeftMenu from '../../components/LeftMenu';
import MuiAutoComplete from '../../components/MuiAutoComplete';
import Breadcrumb from '../../components/Breadcrumb';
import {
  FormLabel,
  TextField,
  CircularProgress,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextareaAutosize
} from '@material-ui/core';
import { updateProfile, getProfile } from '../../actions/profile.action';
import { getCountries } from '../../actions/country.action';
import { useForm, Controller } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  let files = [];
  let images = [];
  const intl = useIntl();
  const [breeder] = React.useState();
  const { register, handleSubmit, watch, errors, control, setValue } = useForm({
    defaultValues: {
      about: props.userData.about ? props.userData.about : "",
      breeder: props.userData.breeder ? "yes" : "not",
      breeder_name: props.userData.breeder_name ? props.userData.breeder_name : "",
      city: props.userData.city ? props.userData.city : "",
      email: props.userData.email ? props.userData.email : "",
      last_name: props.userData.last_name ? props.userData.last_name : "",
      postal_code: props.userData.postal_code ? props.userData.postal_code : "",
      site: props.userData.site ? props.userData.site : "",
      state: props.userData.state ? props.userData.state : "",
      street: props.userData.street ? props.userData.street : "",
      phone: props.userData.phone ? props.userData.phone : "",
      country: props.userData.country ? props.userData.country : "",
      first_name: props.userData.first_name ? props.userData.first_name : ""
    }
  });
  
  if (props.userData.image && props.userData.image.url) {
    images.push(props.userData.image.url);
  }

  const onSubmit = data => {
    if (files.length > 0) {
      data.image = files[0];
    }

    data.country_id = data.country.id;
    delete data.country;

    data.id = props.userData.id;

    if (data.breeder === "yes") {
      data.breeder = true;
    } else {
      data.breeder = false;
    }

    props.submit(data);
  };

  const handleFileChange = (images) => {
    files = [];
    images.map((item) => {
      files.push(item);
    });
  }

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form input-radio">
        <div>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="breeder"
              as={
                <RadioGroup
                  aria-label="breeder"
                  value={breeder}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'breeder' })}
                  />
                  <FormControlLabel
                    value="not"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'not_a_breeder' })}
                  />
                </RadioGroup>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="breeder_name" size="small" label={intl.formatMessage({ id: 'breeder_name' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="first_name" size="small" label={intl.formatMessage({ id: 'first_name' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="last_name" size="small" label={intl.formatMessage({ id: 'last_name' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="phone" size="small" label={intl.formatMessage({ id: 'phone_number' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="site" size="small" label={intl.formatMessage({ id: 'site' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <MuiAutoComplete label={intl.formatMessage({ id: 'country' })} options={props.countries} name="country" control={control} />
          {errors.country && errors.country.type === "required" && <span className="error"><FormattedMessage id="country_is_required" /></span>}
        </div>   
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="postal_code" size="small" label={intl.formatMessage({ id: 'postal_code' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="state" size="small" label={intl.formatMessage({ id: 'state' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="city" size="small" label={intl.formatMessage({ id: 'city' })} variant="outlined" />
        </div>
      </div>
      <div className="input-form">
        <div>
          <TextField inputRef={register()} name="street" size="small" label={intl.formatMessage({ id: 'street' })} variant="outlined" />
        </div>
      </div>     
      <div className="input-form">
        <div className="textarea-holder">
          <FormControl component="fieldset">
            <Controller
              control={control}
              name="about"
              as={
                <TextareaAutosize aria-label="minimum height" rowsMin={5} placeholder={intl.formatMessage({ id: 'about' })} />
              }
            />
          </FormControl>
        </div>
        <div className="info">
          <FormattedMessage id="is_not_allowed_contact" />
        </div>
      </div>
      
      <div className="input-form">
        <div>
          <FormControl component="fieldset">
            <FormLabel><FormattedMessage id="select_an_image" /></FormLabel>
            <br />
            <DropzoneArea
              onChange={handleFileChange}
              name="image"
              filesLimit={1}
              initialFiles={images}
            />
          </FormControl>
          <div className="info">
            <br />
            <FormattedMessage id="want_to_add_more" />
          </div>
        </div>
      </div>
      
      { props.error !== '' && 
        <div className="server-error">          
          <FormattedMessage id="there_contact_busca_cachorro" />
        </div>
      }
      { props.success &&
        <div className="success">
          <Alert severity="success">
            <FormattedMessage id="update_with_success" />
          </Alert>
        </div>
      }
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="add" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class EditProfile extends React.Component {

  state = {
    countries: [],
    user: null,
    success: false,
    submitted: false,
    status: ''
  }

  componentDidMount = () => {
    this.props.getCountries();
    this.props.getProfile(1);

    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    this.setState({status: credentials.subscription_status});
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.country.data !== this.props.country.data) {
      this.setState({
        countries: this.props.country.data.countries
      });
    }

    if (prevProps.profile.data !== this.props.profile.data) {
      let success = false;

      if (this.state.submitted) {
        success = true;
      }

      this.setState({
        success,
        submitted: false,
        user: this.props.profile.data
      });
    }
  }

  submit = (data) => {
    if (data.country) {
      data.country_id = country.id;
      delete data.country;
    }
    this.setState({submitted: true, success: false});
    this.props.updateProfile(data);
  }
  render () {
    return (
      <>
        <Navbar history={this.props.history} />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="user_area" />
              <div className="user-content">
                <LeftMenu />
                <div className="user-content-right">
                  <div className="new-puppy">
                    <h2><FormattedMessage id="pofile_and_breeder_info" /></h2>
                    { this.state.status === 'active' &&                    
                      <div className="new-puppy-link">
                        { this.state.user && <Link to={`/breeder/${this.state.user.id}`}><FormattedMessage id="access_breeder_page_click_here" /></Link> }
                      </div>
                    }
                    { this.state.status !== 'active' &&                    
                      <div className="new-puppy-link">
                        { this.state.user && <Link to={`/subscriptions`}><FormattedMessage id="subscribe_to_have_breeder_directory_page" /></Link> }
                      </div>
                    }
                    { (this.state.countries.length === 0 || !this.state.user) &&
                      <div className="loader whole-content">
                        <CircularProgress />
                      </div>
                    }
                    { (this.state.countries.length > 0 && this.state.user) &&
                      <Form
                        userData={this.state.user}
                        loading={this.props.profile.loading}
                        error={this.props.profile.error}
                        submit={(data) => this.submit(data)}
                        countries={this.state.countries}
                        success={this.state.success}
                      />
                    }
                  </div>
                </div>
              </div>                
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  country: {
    data: state.countryReducer.data,
    loading: state.countryReducer.loading,
    error: state.countryReducer.error
  },
  profile: {
    data: state.profileReducer.data,
    loading: state.profileReducer.loading,
    error: state.profileReducer.error
  }
});

const mapDispatchToProps = {
  getCountries,
  updateProfile,
  getProfile
};

export default connect(structuredSelector, mapDispatchToProps)(EditProfile);
