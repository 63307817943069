import Api from "../services/Api"

export const HOME_LOADING = 'HOME_LOADING';
export const HOME_SUCCESS = 'HOME_SUCCESS';
export const HOME_ERROR = 'HOME_ERROR';

export const getHome = () => dispatch => {
   dispatch({ type: HOME_LOADING });
      Api.getHome()
         .then(response => response.json())
         .then(
          data => dispatch({ type: HOME_SUCCESS, data }),
          error => dispatch({ type: HOME_ERROR, error: error.message || 'Unexpected Error!!!' })
        )
};
