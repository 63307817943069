import Api from "../services/Api"

export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const PROFILE_UPDATE_LOADING = 'PROFILE_UPDATE_LOADING';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';

export const getProfile = (id) => dispatch => {

   dispatch({ type: PROFILE_LOADING });
    Api.getProfile(id)
      .then(response => response.json())
      .then(
        data => dispatch({ type: PROFILE_SUCCESS, data }),
        error => dispatch({ type: PROFILE_ERROR, error: error.message || 'Unexpected Error!!!' })
      )
};

 export const updateProfile = (data) => dispatch => {

  dispatch({ type: PROFILE_UPDATE_LOADING });

   Api.updateProfile(data)
    .then(response => response.json())
    .then(
      data => dispatch({ type: PROFILE_UPDATE_SUCCESS, data }),
      error => dispatch({ type: PROFILE_UPDATE_ERROR, error: error.message || 'Unexpected Error!!!' })
    )
};
