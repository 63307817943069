import { BREED_LOADING, BREED_SUCCESS, BREED_ERROR, CANCEL_API_REQUEST } from "../actions/breed.action";

const initialState = {
  data: [],
  loading: false,
  error: '',
  aborted: false
};

export default function breedReducer(state = initialState, action) {
  switch (action.type) { 
    case BREED_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:''
      }; 
    } 
    case BREED_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case BREED_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    }
    case CANCEL_API_REQUEST: {
      return { 
        ...state, 
        loading: false,
        aborted: true,
      }; 
    }
    default: { 
      return state; 
    } 
  } 
 }
