import Api from "../services/Api"

export const ARTICLE_LOADING = 'ARTICLE_LOADING';
export const ARTICLE_SUCCESS = 'ARTICLE_SUCCESS';
export const ARTICLE_ERROR = 'ARTICLE_ERROR';

export const getArticle = (tag, language) => dispatch => {

   dispatch({ type: ARTICLE_LOADING });

    Api.getArticle(tag, language)
       .then(response => response.json())
       .then(
            data => dispatch({ type: ARTICLE_SUCCESS, data }),
            error => dispatch({ type: ARTICLE_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};