import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null
    };
  }

  componentDidMount = () => {
    const lang = localStorage.getItem('lang') || "en" ;
    this.setState({
      lang: lang
    })
  }

  render () {
    const lang = this.state.lang;

  	return (
      <footer>
        <div className="content">
          <div className="footer-list">
            <div className="footer-menu">
              <h2 className="logo"><FormattedMessage id="busca_cachorro" /></h2>
              <ul>
                <li>
                  <Link to={`/${lang}/breeders`}>
                    <FormattedMessage id="all_breeders" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/breeders-countries`}>
                    <FormattedMessage id="dog_breeders_by_country" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/clubs-caninos`}>
                    <FormattedMessage id="clubs_caninos" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <h3><FormattedMessage id="services" /></h3>
              <ul>
                <li>
                  <Link to={`/${lang}/find-my-breed`}>
                    <FormattedMessage id="find_my_breed" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/login`}>
                    <FormattedMessage id="sell_a_puppy" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <h3><FormattedMessage id="useful_links" /></h3>
              <ul>
                <li>
                  <Link to={`/${lang}/about-us`}>
                    <FormattedMessage id="about" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/terms-of-service`}>
                    <FormattedMessage id="terms_of_services" />                    
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/currency-converter`}>
                    <FormattedMessage id="currency_converter" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/payment-services`}>
                    <FormattedMessage id="payment_services" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/buyer-protection`}>
                    <FormattedMessage id="buyer_protection" />                    
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/seller-protection`}>
                    <FormattedMessage id="seller_protection" />                    
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/disclaimer`}>
                    <FormattedMessage id="disclaimer" />
                  </Link>
                </li>
                <li>
                  <Link to={`/${lang}/links`}>
                    <FormattedMessage id="links" />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-menu">
              <h3><FormattedMessage id="contact" /></h3>
              <span>
                <a href="mailto:contact@buscacachorro.com">contact@buscacachorro.com</a>
              </span>
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <div className="content">
            Copyright (C) 2020 buscacachorro.com
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
