import Api from "../services/Api"

export const REGISTRATION_LOADING = 'REGISTRATION_LOADING';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTRATION_ERROR = 'REGISTRATION_ERROR';

export const createRegistration = (email, password, confirm_password) => dispatch => {

   dispatch({ type: REGISTRATION_LOADING });

    Api.createRegistration(email, password, confirm_password)
       .then(response => response.json())
       .then(
            data => dispatch({ type: REGISTRATION_SUCCESS, data }),
            error => dispatch({ type: REGISTRATION_ERROR, error: error.message || 'Unexpected Error!!!' })
       )
};