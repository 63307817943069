import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import Breadcrumb from '../../components/Breadcrumb';
import { loadKidRoutine, updateKidRoutine } from '../../actions/kidRoutine.action';
import { updateKidTask } from '../../actions/kidTask.action';
import diamond from 'images/tasks/diamond.svg';
import moment from 'moment';

class Kid extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      kidsTasks: [],
      currentTask: null,
      kidName: '',
      rewardsTotal: 0
    }
  }

  componentDidMount = () => {
    if (this.props.match.params.routineId) {
      this.props.loadKidRoutine(Number(this.props.match.params.routineId));
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.kidRoutine.data !== this.props.kidRoutine.data) {
      const kidTasks = this.props.kidRoutine.data.kid_tasks.filter((item) => item.end_date === null);
      if (this.props.kidRoutine.data.end_date) {
        this.setState({
          kidsTasks: [],
          kidName: this.props.kidRoutine.data.routine.kid.name,
          rewardsTotal: this.props.kidRoutine.data.reward_total
        });
      } else {
        this.setState({
          kidsTasks: kidTasks,
          kidName: this.props.kidRoutine.data.routine.kid.name,
          rewardsTotal: this.props.kidRoutine.data.reward_total
        });
      }
    }

    if (prevState.kidsTasks !== this.state.kidsTasks) {
      this.setCurrentTask(0);
    }

    if (prevProps.taskRoutine.data !== this.props.taskRoutine.data) {
      this.setState({
        rewardsTotal: this.props.taskRoutine.data.reward_total
      });
    }
  }

  setCurrentTask = (current) => {
    const currentTask = this.state.kidsTasks[current];
    this.setState({currentTask: currentTask});
  }

  taskDone = () => {
    const tasks = this.state.kidsTasks.filter((item) => item.id !== this.state.currentTask.id);
    const today = moment(new Date()).format('YYYY-MM-DD hh:mm:ss');

    if (tasks.length === 0) {
      this.props.updateKidRoutine(
        this.props.kidRoutine.data.id,
        this.props.kidRoutine.data.routine.id,
        null,
        today
      );
      this.props.updateKidTask(
        this.state.currentTask.id,
        this.props.kidRoutine.data.routine.id,
        this.props.kidRoutine.data.id,
        null,
        today
      );
    } else {
      this.props.updateKidTask(
        this.state.currentTask.id,
        this.props.kidRoutine.data.routine.id,
        this.props.kidRoutine.data.id,
        null,
        today
      );
    }

    this.setState({kidsTasks: tasks});
  }

  taskAfter = () => {
    const tasks = this.state.kidsTasks.filter((item) => item.id !== this.state.currentTask.id);
    tasks.push(this.state.currentTask);

    this.setState({kidsTasks: tasks});
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="kid" />
              <div className="login-content">
                <div className="form-content">
                  <div className="diamonds">
                    <span>{this.state.rewardsTotal}</span>
                    <img src={diamond} />
                  </div>
                  { this.props.kidRoutine.loading === false &&
                    <h2>{this.state.kidName}</h2>
                  }
                  { this.state.kidsTasks.length === 0 &&
                    <h2>Parabens</h2>
                  }
                  { this.state.currentTask &&
                    <div className="task_card">
                      <div className="task">
                        { this.state.currentTask.name }
                      </div>
                      <div className="nav">
                        <button onClick={() => this.taskDone()}>Fiz a tarefa</button>
                        <button onClick={() => this.taskAfter()}>Deixar para depois</button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  kidRoutine: {
    data: state.kidRoutineReducer.data,
    loading: state.kidRoutineReducer.loading,
    error: state.kidRoutineReducer.error
  },
  taskRoutine: {
    data: state.kidTaskReducer.data,
    loading: state.kidTaskReducer.loading,
    error: state.kidTaskReducer.error
  }
});

const mapDispatchToProps = {
  loadKidRoutine,
  updateKidRoutine,
  updateKidTask
};

export default connect(structuredSelector, mapDispatchToProps)(Kid);
