import {
  PROFILE_LOADING,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_UPDATE_LOADING,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR
} from "../actions/profile.action";

const initialState = {
 data: [],
 loading: false,
 error: ''
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) { 
    case PROFILE_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case PROFILE_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case PROFILE_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    }
    case PROFILE_UPDATE_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case PROFILE_UPDATE_SUCCESS: { 
      return {
        ...state, 
        data: action.data, 
        loading: false 
      }
    } 
    case PROFILE_UPDATE_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
}