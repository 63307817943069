import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import { TextField, CircularProgress, Button } from '@material-ui/core';
import { createRegistration } from '../actions/registration.action';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

function Form(props) {
  const intl = useIntl();
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = data => {
    props.submit(data);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="input-form">
        <TextField inputRef={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} name="email" size="small" label={intl.formatMessage({ id: 'email' })} variant="outlined" />
        {errors.email && errors.email.type === "required" && <span className="error"><FormattedMessage id="email_is_required" /></span>}
        {errors.email && errors.email.type === "pattern" && <span className="error"><FormattedMessage id="email_is_not_valid" /></span>}
      </div>
      <div className="input-form">
        <TextField inputRef={register({ required: true, minLength: 6 })} type="password" name="password" size="small" label={intl.formatMessage({ id: 'password' })} variant="outlined" />
        {errors.password && errors.password.type === "required" && <span className="error"><FormattedMessage id="password_is_required" /></span>}
        {errors.password && errors.password.type === "minLength" && <span className="error"><FormattedMessage id="min_length_is_6" /></span> }
      </div>
      <div className="input-form">
        <TextField inputRef={register({ required: true, minLength: 6, validate: (value) => value === watch('password') })} type="password" name="confirm_password" size="small" label={intl.formatMessage({ id: 'confirm_password' })} variant="outlined" />
        {errors.confirm_password && errors.confirm_password.type === "required" && <span className="error"><FormattedMessage id="confirm_password_is_required" /></span>}
        {errors.confirm_password && errors.confirm_password.type === "minLength" && <span className="error"><FormattedMessage id="min_length_is_6" /></span> }
        {errors.confirm_password && errors.confirm_password.type === "validate" && <span className="error"><FormattedMessage id="passwords_dont_match" /></span> }
      </div>
      { props.error !== '' && 
        <div className="server-error">
          <FormattedMessage id="email_already_taken" />
        </div>
      }
      <div className="input-form button-form">
        <Button type="submit" variant="contained" disabled={props.loading}>
          { !props.loading &&
            <FormattedMessage id="sign_up" />
          }
          { props.loading &&
            <CircularProgress size={26} />
          }
        </Button>
      </div>
    </form>
  );
}

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: '/listing',
      subscribe: false
    }
  }

  componentDidMount = () => {
    const page = this.props.match.path.replace('/', '');
    if (page === "sell-a-puppy") {
      this.setState({page: '/new-puppy'})
    }
    if (page !== "sell-a-puppy" && this.props.match.params.subscribe) {
      this.setState({subscribe: true, page: '/subscriptions'})
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.registration.data !== this.props.registration.data) {
      localStorage.setItem('taskCredentials', JSON.stringify(this.props.registration.data));
      this.props.history.push(this.state.page);
    }
  }
  
  process = (data) => {
    this.props.createRegistration(data.email, data.password, data.confirm_password);
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="login">
              <Breadcrumb title="sign_up" />
              <div className="login-content">
                <div className="form-content">
                  <h2><FormattedMessage id="sign_up" /></h2>
                  <Form 
                    loading={this.props.registration.loading}
                    error={this.props.registration.error}
                    submit={(data) => this.process(data)} 
                  />
                </div>
                <div className="form-links">
                  <ul>
                    <li>
                      <Link to={this.state.subscribe ? '/login/subscribe' : '/login'}>
                        <FormattedMessage id="login" />
                      </Link>
                    </li>
                    <li>
                      <Link to="/forgotten">
                        <FormattedMessage id="forgot_your_password" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  registration: {
    data: state.registrationReducer.data,
    loading: state.registrationReducer.loading,
    error: state.registrationReducer.error
  }
});

const mapDispatchToProps = {
  createRegistration
};

export default connect(structuredSelector, mapDispatchToProps)(Signup);
