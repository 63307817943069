import { COUNTRY_LOADING, COUNTRY_SUCCESS, COUNTRY_ERROR } from "../actions/country.action";

const initialState = {
   data: [],
   loading: false,
   error: ''
};

export default function countryReducer(state = initialState, action) {
  switch (action.type) { 
    case COUNTRY_LOADING: { 
      return { 
        ...state, 
        loading: true, 
        error:'' 
      }; 
    } 
    case COUNTRY_SUCCESS: { 
      return { 
        ...state, 
        data: action.data, 
        loading: false 
      } 
    } 
    case COUNTRY_ERROR: { 
      return { 
        ...state, 
        loading: false, 
        error: action.error 
      }; 
    } 
    default: { 
      return state; 
    } 
  } 
 }