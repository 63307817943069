import React from 'react';
import { connect } from 'react-redux';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Breadcrumb from '../components/Breadcrumb';
import PuppyItem from '../components/PuppyItem';
import { getPuppy } from '../actions/puppy.action';
import ContactBreeder from '../components/ContactBreeder';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from "react-router-dom";

function Age(props) {
  const intl = useIntl();
  const getAge = (born) => {
    const birth = moment(born);
    const now = moment();
    let days = now.diff(birth, 'days');
    let word = intl.formatMessage({ id: 'weeks' });
    if (days < 7) {
      days = 7;
      word = intl.formatMessage({ id: 'week' });
    }
    return `${ Math.floor(days / 7)} ${word}`;
  }

  return (
    <span>{ getAge(props.born) }</span>
  )
}

class Puppy extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      puppy: null,
      puppyId: null,
      user: null
    }
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.startComponent();
    }

    const credentials = JSON.parse(localStorage.getItem('taskCredentials'));
    if (credentials) {
      this.setState({
        user: credentials
      });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.puppy.data !== this.props.puppy.data) {
      this.setState({
        puppy: this.props.puppy.data
      });
    }

    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.startComponent();
    }
  }

  startComponent = () => {
    const puppyId = this.props.match.params.id;
    this.props.getPuppy(puppyId);
    this.setState({puppyId});
  }

  render () {
  	return (
      <>
        <Navbar />
        <div className="main-content">
          <div className="content">
            <div className="puppy">
              <Breadcrumb title="puppy" />
              {this.state.puppy &&
                <h2>{this.state.puppy.name} / {this.state.puppy.breed.name}</h2>
              }
              <div className="puppy-content main-puppy-content">
                {this.state.puppy &&
                  <>
                    <div className="item gallery">
                      {this.state.puppy.images.length > 0 &&
                        <ImageGallery items={this.state.puppy.images} />
                      }
                    </div>
                    {/* <div className="video"></div> */}
                    <div className="item desc about">
                      <h3><FormattedMessage id="about" /> {this.state.puppy.name}</h3>
                      <ul>
                        <li>
                          <h4><FormattedMessage id="gender" /></h4>
                          <span>{ this.state.puppy.gender }</span>
                        </li>
                        {this.state.puppy.born &&
                          <li>
                            <h4><FormattedMessage id="age" /></h4>
                            <Age born={this.state.puppy.born} />
                          </li>
                        }
                        <li>
                          <h4><FormattedMessage id="price" /></h4>
                          <span>{ this.state.puppy.price }</span>
                        </li>
                        {this.state.puppy.champion_bloodlines &&
                          <li>
                            <h4><FormattedMessage id="champion_bloodlines" /></h4>
                            <span><FormattedMessage id={ String(this.state.puppy.champion_bloodlines) } /></span>
                          </li>
                        }
                        {this.state.puppy.champion_sired &&
                          <li>
                            <h4><FormattedMessage id="champion_sired" /></h4>
                            <span><FormattedMessage id={ String(this.state.puppy.champion_sired) } /></span>
                          </li>
                        }
                        {this.state.puppy.show_potential &&
                          <li>
                            <h4><FormattedMessage id="show_potential" /></h4>
                            <span><FormattedMessage id={ String(this.state.puppy.show_potential) } /></span>
                          </li>
                        }
                        {this.state.puppy.country &&
                          <li>
                            <h4><FormattedMessage id="shipping_area" /></h4>
                            <span>{this.state.puppy.country.name}</span>
                          </li>
                        }     
                      </ul>
                    </div>
                    { ( this.state.puppy.registered_registerable ||
                        this.state.puppy.veterinarian_examination ||
                        this.state.puppy.health_certificate ||
                        this.state.puppy.health_guarantee ||
                        this.state.puppy.pedigree ||
                        this.state.puppy.travel_crate) &&
                      <div className="item desc puppy-content puppy-content-included">
                        <h3><FormattedMessage id="included_items" /></h3>
                        <ul>
                          {this.state.puppy.registered_registerable &&
                            <li><FormattedMessage id="registered_registerable" /></li>
                          }
                          {this.state.puppy.veterinarian_examination &&
                            <li><FormattedMessage id="veterinarian_examination" /></li>
                          }
                          {this.state.puppy.health_certificate &&
                            <li><FormattedMessage id="health_certificate" /></li>
                          }
                          {this.state.puppy.health_guarantee &&
                            <li><FormattedMessage id="health_guarantee" /></li>
                          }
                          {this.state.puppy.pedigree &&
                            <li><FormattedMessage id="pedigree" /></li>
                          }
                          {this.state.puppy.travel_crate &&
                            <li><FormattedMessage id="travel_crate" /></li>
                          }
                        </ul>
                      </div>
                    }
                    { this.state.puppy.description &&
                      <div className="item desc puppy-content">
                        <h3><FormattedMessage id="description" /></h3>
                        <p>
                          {this.state.puppy.description}
                        </p>
                      </div>
                    }
                    { (this.state.puppy.include_info || this.state.puppy.deliver_info) &&
                      <div className="item desc more-info">
                        { this.state.puppy.include_info &&
                          <>
                            <h3><FormattedMessage id="includes" /></h3>
                            <p>
                              { this.state.puppy.include_info }
                            </p>
                          </>
                        }
                        { this.state.puppy.deliver_info &&
                          <>
                            <h3><FormattedMessage id="deliver" /></h3>
                            <p>
                              { this.state.puppy.deliver_info }
                            </p>
                          </>
                        }
                      </div>
                    } 
                  </>
                }
              </div>
              {this.state.puppy && this.state.user && this.state.user.subscription_status && this.state.user.subscription_status === 'active' && (
                <div className="puppies-puppy">
                  <h3><FormattedMessage id="about_the_breeder" /></h3>
                  <ContactBreeder show={true} breeder={this.state.puppy.user} />
                </div>
              )}
              {this.state.puppy && (!this.state.user || (this.state.user.subscription_status && this.state.user.subscription_status !== 'active')) && (
                <div className="puppies-upgrade">
                  {this.state.user && (this.state.user.subscription_status && this.state.user.subscription_status !== 'active') && (
                    <Link className="breeder-site" to="/subscriptions"><FormattedMessage id="please_upgrade" /></Link>
                  )}
                  {!this.state.user && (
                    <Link className="breeder-site" to="/login/subscribe"><FormattedMessage id="please_upgrade" /></Link>
                  )}
                  <div><FormattedMessage id="seller_upgrade_info" /></div>
                </div>
              )}
              { (this.state.puppy && this.state.puppy.puppies.length > 0) &&
                <div className="puppies-content puppies-puppy">
                  <h3><FormattedMessage id="other_puppies_from_this_breeder" /></h3>
                  <ul>
                    { this.state.puppy.puppies.map (puppy => (
                      <li key={`puppie-${puppy.id}`}>
                        <PuppyItem 
                          id={puppy.id}
                          title={puppy.breed.name}
                          image={(puppy.images && puppy.images.length > 0 ) ? puppy.images[0].url : null}
                          price={puppy.price}
                          description={`${puppy.name && puppy.name} | ${puppy.country.name}`}
                        />
                      </li>
                    ))}                
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const structuredSelector = state => ({
  puppy: {
    data: state.puppyReducer.data,
    loading: state.puppyReducer.loading,
    error: state.puppyReducer.error
  }
});

const mapDispatchToProps = {
  getPuppy
};


export default connect(structuredSelector, mapDispatchToProps)(Puppy);
