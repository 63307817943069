import React from 'react';
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

class LeftMenu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lang: null
    };
  }

  componentDidMount = () => {
    const lang = localStorage.getItem('lang') || "en" ;
    this.setState({
      lang: lang
    })
  }

  render () {
    const lang = this.state.lang;
  	return (
      <div className="left-menu">
        <ul>
          <li>
            <Link to={`/${lang}/listing`}>
              <FormattedMessage id="listing" />
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/puppy-wanted-create`}>
              <FormattedMessage id="puppy_wanted_create_title" />
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/profile`}>
              <FormattedMessage id="profile" />
            </Link>
          </li>
          <li>
            <Link to={`/${lang}/subscriptions`}>
              <FormattedMessage id="subscriptions" />
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default LeftMenu;
